import React from 'react';

// Setting Up Little Context To Pass IsCT Local Storage State Through
const ctContext = React.createContext<
  [boolean, React.Dispatch<React.SetStateAction<boolean>>]
>([] as any);

export const IsCTProvider = ctContext.Provider;

export const useCTContext = () => {
  const context = React.useContext(ctContext);
  return context;
};
