import { ManagedSingleSiteApplication } from '@rapid/sdk';
import { setAutoFreeze } from 'immer';
import React from 'react';
import IndexPage from './IndexPage';
import { StoreProvider } from './Store';

setAutoFreeze(false);

export default function App() {
  return (
    <ManagedSingleSiteApplication
      className="gce-layout-default"
      env={process.env.REACT_APP_ENV ?? 'test'}
      clientId={process.env.REACT_APP_CLIENT_ID!}
      site="internal"
      tenant="guardianchildcare"
      displayName="L2E Portal"
      namespace="guardianchildcare_waitlist"
    >
      <StoreProvider>
        <IndexPage />
      </StoreProvider>
    </ManagedSingleSiteApplication>
  );
}
