import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  ListOnItemsRenderedProps,
  VariableSizeList as List,
} from 'react-window';
import './TimePicker.scss';

export interface IValidTimeRange {
  start?: TTimes;
  end?: TTimes;
}

interface ITimePickerProps {
  validTimeRange?: IValidTimeRange;
  onChange(value: DateTime): void;
  value?: DateTime;
}

export default function TimePicker({
  validTimeRange,
  onChange,
  value,
}: ITimePickerProps) {
  const validTimes = useMemo(
    function filterBlockedTimesEffect() {
      const validTimes = times.filter(t => {
        if (
          t >= (validTimeRange?.start ?? '00:00') &&
          t <= (validTimeRange?.end ?? '24:00')
        ) {
          return t;
        }
      });

      return validTimes;
    },
    [validTimeRange],
  );

  const [visibleStart, setVisibleStart] = useState<number>(0);
  const [visibleEnd, setVisibleEnd] = useState<number>(validTimes?.length);

  const onItemsRendered = (items: ListOnItemsRenderedProps) => {
    if (visibleEnd !== items.visibleStopIndex) {
      setVisibleEnd(items.visibleStopIndex);
    }

    if (visibleStart !== items.visibleStartIndex) {
      setVisibleStart(items.visibleStartIndex);
    }
  };

  const onSelectTime = (time: TTimes) => () => {
    const dt = DateTime.fromFormat(time, 'hh:mm');

    onChange(dt);
  };

  useEffect(
    function scrollToSelectedTime() {
      const activeTime = value?.toFormat('HH:mm');

      const ind = validTimes.findIndex(t => t === activeTime) ?? -1;

      if (ind !== -1 && (ind < visibleStart || ind > visibleEnd)) {
        listRef.current?.scrollToItem(ind, 'center');
      }
    },
    [value],
  );

  const TimeRow = ({ index, style }) => {
    const evenOddClass = index % 2 === 0 ? ' even' : ' odd';

    const activeClass =
      value?.toFormat('HH:mm') === validTimes[index] ? ' active' : '';

    return (
      <div
        onClick={onSelectTime(validTimes[index])}
        className="TimeContainer"
        key={validTimes?.[index]}
        style={style}
      >
        <span className={`Time${evenOddClass}${activeClass}`}>
          {validTimes[index]}
        </span>
      </div>
    );
  };

  const listRef = useRef<List>(null);

  return (
    <div className="TimePicker">
      <List
        ref={listRef}
        itemSize={() => 35}
        width={100}
        height={343}
        itemCount={validTimes.length}
        onItemsRendered={onItemsRendered}
      >
        {TimeRow}
      </List>
    </div>
  );
}

export type TTimes =
  | '00:00'
  | '00:30'
  | '01:00'
  | '01:30'
  | '02:00'
  | '02:30'
  | '03:00'
  | '03:30'
  | '04:00'
  | '04:30'
  | '05:00'
  | '05:30'
  | '06:00'
  | '06:30'
  | '07:00'
  | '07:30'
  | '08:00'
  | '08:30'
  | '09:00'
  | '09:30'
  | '10:00'
  | '10:30'
  | '11:00'
  | '11:30'
  | '12:00'
  | '12:30'
  | '13:00'
  | '13:30'
  | '14:00'
  | '14:30'
  | '15:00'
  | '15:30'
  | '16:00'
  | '16:30'
  | '17:00'
  | '17:30'
  | '18:00'
  | '18:30'
  | '19:00'
  | '19:30'
  | '20:00'
  | '20:30'
  | '21:00'
  | '21:30'
  | '22:00'
  | '22:30'
  | '23:00'
  | '23:30';

const times: TTimes[] = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
];
