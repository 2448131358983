import { Updater } from '@rapid/sdk';
import React, { useState } from 'react';
import useComposedClassName from '@rapid-platform/use-composed-class-name';
import { AnyListItem } from '@rapid/data-model';
import './LightboxRender.scss';
import {
  TLightboxMode,
  TLightboxOnClose,
  TSchedulerTour,
} from '../../Types/SchedulerTypes';
import { ILead } from '../../Types/ItemTypes';
import Lightbox from '../../../Lightbox/Lightbox';
import CreateLead from './Lead/CreateLead';
import CRUDTour from './Tour/CRUDTour';
import CreateBlocking from './Blocking/CreateBlocking';
import { useCTContext } from '../../Contexts/CTContext/CTContext';
import SelectTime from './SelectTime/SelectTime';
import EditBlocking from './Blocking/EditBlocking';
import { IUpdateTourData } from '../../CRUD/updateTour';
import LeadSelection from './Lead/LeadSelection';
import ContactSelection from './Contact/ContactSelection';
import ContactLeadSelection from './Contact/ContactLeadSelection';
import CTTabs from './CTTabs/CTTabs';
import ConfirmRemoveBlocking from './ConfirmCancel/ConfirmRemoveBlocking';
import ConfirmCancel from './ConfirmCancel/ConfirmCancel';
import ConfirmReschedule from './ConfirmCancel/ConfirmReschedule';
import { linkedParentTag } from '../../Utils/SchedulerSymbols';

interface ILightboxRenderProps {
  event: Partial<TSchedulerTour>;
  updateEvent: Updater<Partial<TSchedulerTour>>;
  onCloseLightbox(onClose: TLightboxOnClose): void;

  mode: TLightboxMode;
  setLightboxMode(mode: TLightboxMode): void;

  onRescheduleTour(): void;

  onCancelTour(): void;
  onConfirmCancel(): void;

  onCreateTour(data: IUpdateTourData): void;

  onSaveTour(data: IUpdateTourData): void;

  onSelectLead(lead: Partial<AnyListItem<ILead>>, single?: boolean): void;
  timezone?: string;
}

export default function LightboxRender({
  event,
  updateEvent,
  onCloseLightbox,
  onRescheduleTour,
  mode,
  setLightboxMode,

  onSelectLead,

  onCreateTour,

  onSaveTour,

  onCancelTour,
  onConfirmCancel,
  timezone: zone,
}: ILightboxRenderProps) {
  const [isCT] = useCTContext();

  const [contactSelected, setContactSelected] = useState(false);

  const _onCloseLightbox = () => {
    onCloseLightbox('close');
  };

  const onSetCreateLead = () => {
    setLightboxMode('create-lead');
  };

  const onSetCreateTour = () => {
    setLightboxMode('create-tour');
  };

  const onSetReschedule = () => {
    setLightboxMode('confirm-reschedule');
  };

  const onCancelCreateLead = () => {
    if (!!event?.[linkedParentTag]?.id) {
      setLightboxMode('select-lead');
      return;
    }

    setLightboxMode('select-contact-or-lead');
  };

  const onLeadCreated = () => {
    setLightboxMode('select-lead');
  };

  const title = useComposedClassName(
    function* () {
      if (mode === 'create-lead') yield 'Create Lead';
      else if (mode === 'select-contact-or-lead' && !!isCT) yield 'Find Lead';
      else if (mode === 'select-contact-or-lead' && !isCT) yield 'Find Contact';
      else if (mode === 'select-lead') yield 'Select Lead';
      else if (mode === 'create-tour') yield 'Create Tour';
      else if (mode === 'edit-tour') yield 'Edit Tour';
      else if (mode === 'confirm-cancel') yield 'Confirm Cancel';
      else if (mode === 'create-blocking') yield 'Block Timeslot';
      else if (mode === 'edit-blocking') yield 'Edit Blocked Timeslot';
      else if (mode === 'confirm-reschedule') yield 'Confirm Reschedule';
      else if (mode === 'remove-blocking')
        yield 'Confirm remove blocked timeslot';
      else if (mode === 'create-one-off') yield 'Select Time';

      if (
        !!event?.text &&
        (mode === 'edit-tour' ||
          mode === 'create-tour' ||
          mode === 'confirm-reschedule')
      )
        yield event?.text;
    },
    [mode, event?.text, isCT, contactSelected],
  );

  const onSelectSingleLead = (lead: AnyListItem<ILead>) => {
    onSelectLead(lead, true);
  };

  return (
    <Lightbox>
      <div className="LightboxRender">
        <div className="LightboxHeader">
          <div className="LightboxTitle" title={title}>
            {title}
          </div>
          <i
            className="fas fa-times CloseLightbox"
            onClick={_onCloseLightbox}
            title="Close Lightbox Without Saving"
          />
        </div>

        {mode === 'select-contact-or-lead' && (
          <div className="TourCreation">
            <CTTabs />

            {!!isCT && (
              <LeadSelection
                onSelectLead={onSelectSingleLead}
                onCreateLead={onSetCreateLead}
              />
            )}

            {!isCT && (
              <ContactSelection
                event={event}
                updateEvent={updateEvent}
                onCreateLead={onSetCreateLead}
                setContactSelected={setContactSelected}
              />
            )}
          </div>
        )}

        {mode === 'select-lead' && (
          <ContactLeadSelection
            event={event}
            updateEvent={updateEvent}
            onSelectLead={onSelectLead}
            onCreateLead={onSetCreateLead}
            onCreateTour={onSetCreateTour}
          />
        )}

        {mode === 'create-lead' && (
          <CreateLead
            onLeadCreated={onLeadCreated}
            onCancelCreateLead={onCancelCreateLead}
            updateEvent={updateEvent}
            event={event}
          />
        )}

        {mode === 'create-tour' && (
          <CRUDTour
            event={event}
            updateEvent={updateEvent}
            onCreateTour={onCreateTour}
            mode="Create"
            timezone={zone}
          />
        )}

        {mode === 'edit-tour' && (
          <CRUDTour
            event={event}
            updateEvent={updateEvent}
            onRescheduleTour={onSetReschedule}
            onSaveTour={onSaveTour}
            onCancelTour={onCancelTour}
            mode="Edit"
            timezone={zone}
          />
        )}

        {mode === 'create-one-off' && (
          <SelectTime
            event={event}
            updateEvent={updateEvent}
            setLightboxMode={setLightboxMode}
            timezone={zone}
          />
        )}

        {mode === 'create-blocking' && (
          <CreateBlocking onCloseLightbox={onCloseLightbox} timezone={zone} />
        )}

        {mode === 'edit-blocking' && (
          <EditBlocking
            setLightboxMode={setLightboxMode}
            onCloseLightbox={onCloseLightbox}
            timezone={zone}
          />
        )}

        {mode === 'remove-blocking' && (
          <ConfirmRemoveBlocking onCloseLightbox={onCloseLightbox} />
        )}

        {mode === 'confirm-cancel' && (
          <ConfirmCancel event={event} onConfirmCancel={onConfirmCancel} />
        )}

        {mode === 'confirm-reschedule' && (
          <ConfirmReschedule
            event={event}
            onRescheduleTour={onRescheduleTour}
          />
        )}
      </div>
    </Lightbox>
  );
}
