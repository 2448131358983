import { Button } from 'antd';
import React, { useEffect } from 'react';
import { Updater, useImmer } from '@rapid/sdk';
import './CRUDTour.scss';
import { TSchedulerTour } from '../../../Types/SchedulerTypes';
import useTourLinkedItems from '../../../Hooks/useTourLinkedItems';
import TourTime from './TourTime';
import TourLinkedLeads from './TourLinkedLeads';
import TourForm from './TourForm';
import { useLightbox } from '../../../Contexts/LightboxContext/LightboxContext';
import { IUpdateTourData } from '../../../CRUD/updateTour';

interface ICreateTourProps {
  event?: Partial<TSchedulerTour>;
  updateEvent: Updater<Partial<TSchedulerTour>>;
  onCreateTour?(data: IUpdateTourData): void;
  onRescheduleTour?(): void;
  onSaveTour?(data: IUpdateTourData): void;
  onCancelTour?(): void;
  mode: 'Edit' | 'Create';
  timezone?: string;
}

export default function CRUDTour({
  event,
  updateEvent,
  onCreateTour,
  onRescheduleTour,
  onSaveTour,
  onCancelTour,
  mode,
  timezone: zone,
}: ICreateTourProps) {
  const { loading: lbLoading } = useLightbox();

  const { loadingLeads, loadingFamily, loadingParent } = useTourLinkedItems(
    event,
    updateEvent,
    mode,
  );

  const [tourData, updateTourData] = useImmer<IUpdateTourData>({});

  const _onCreateTour = () => {
    onCreateTour?.(tourData);
  };

  const _onSave = () => {
    onSaveTour?.(tourData);
  };

  return (
    <div className="CRUDTour">
      <TourTime event={event} timezone={zone} />
      <TourLinkedLeads event={event} loadingLeads={loadingLeads} />
      <TourForm
        event={event}
        updateTourData={updateTourData}
        loading={loadingParent && loadingFamily}
      />
      <div className="TourActions">
        {mode === 'Create' && (
          <Button
            onClick={_onCreateTour}
            type="primary"
            className="CRUDButton Create"
            disabled={lbLoading}
          >
            Create
          </Button>
        )}
        {mode === 'Edit' && (
          <>
            <Button
              onClick={onCancelTour}
              type="text"
              danger
              className="CRUDButton Cancel"
              disabled={lbLoading}
            >
              Cancel Tour
            </Button>
            <Button
              onClick={onRescheduleTour}
              type="text"
              className="CRUDButton Reschedule"
              disabled={lbLoading}
            >
              Reschedule
            </Button>
            <Button
              onClick={_onSave}
              type="primary"
              className="CRUDButton Save"
              disabled={lbLoading}
            >
              Save
            </Button>
          </>
        )}
      </div>
    </div>
  );
}
