import { DateTime, Zone } from 'luxon';

/**
 * Increments the actual dateTime value by the delta of the local timezone offset and the provided timezone offset.
 * Example: A centre tour in South Yarra is begining at 10am Australia/Sydney DST. When this tour is viewed in
 * Australia/Brisbane, the time would appear as 9am. This function, with direction 'Zone to Local', would produce
 * a time that would be represented as 10am in Australian/Brisbane. This allows users in other timezones to view
 * the tour schedule as if they were in the same timezone as the centre. Direction 'Local to Zone', the inverse of
 * this operation is applied.
 * @param dateTime
 * @param timeZone
 * @param direction Zone to Local converts dateTime to the apparent zone time. Local to Zone reverts the operation.
 * @returns
 */
export default function OffsetTime(
  dateTime: DateTime,
  timeZone?: string | Zone,
  direction: 'Zone to Local' | 'Local to Zone' = 'Zone to Local',
) {
  const offset =
    direction === 'Zone to Local'
      ? dateTime.setZone(timeZone).offset - dateTime.toLocal().offset
      : dateTime.toLocal().offset - dateTime.setZone(timeZone).offset;

  return dateTime.plus({ minutes: offset });
}
