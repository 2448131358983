import { IField } from '@rapid/data-model';
import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { formValidators, pick } from './form-validators';
import './form.scss';

export type TDynamicFormContext = [
  object,
  object,
  (name: string) => IField | undefined,
  (field: IField, name: string, value: unknown) => string | void,
  Dispatch<SetStateAction<object>>,
  Dispatch<SetStateAction<object>>,
  IField[],
  { current: Map<string, { isRequired?: boolean }> },
];

export const DynamicFormContext = createContext<TDynamicFormContext>(undefined as any);

export interface IDynamicFormProps {
  fields: IField[];
  initialValue?: object;
  onUpdate: (formValue: any, errors: any) => void;
}

export default function DynamicForm(props: PropsWithChildren<IDynamicFormProps>) {
  const rules = useRef(new Map());

  const toKeep = useMemo(() => {
    return props.fields
      .filter(field => {
        return !['Computed', 'User', 'Subquery', 'MultiLookup'].includes(field.FieldType);
      })
      .map(field => field.ColumnName)
      .concat(['id', '__metadata']);
  }, []);

  const [formValue, updateFormValue] = useState<object>(pick(props.initialValue!, toKeep));
  const [errors, updateErrors] = useState<object>({});

  const getField = (name: string) => props.fields.find(field => field.ColumnName === name);

  const validate = useCallback((field: IField, name: string, value: unknown) => {
    updateErrors(draft => ({
      ...draft,
      [name]: formValidators[field.FieldType]?.(
        value,
        rules.current.get(name) ?? { isRequired: true },
      ),
    }));
  }, []);

  useEffect(() => {
    props.onUpdate(formValue, errors);
  }, [formValue, errors]);

  return (
    <DynamicFormContext.Provider
      value={[
        formValue,
        errors,
        getField,
        validate,
        updateFormValue,
        updateErrors,
        props.fields,
        rules,
      ]}
    >
      {props.children}
    </DynamicFormContext.Provider>
  );
}
