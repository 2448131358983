import { ISiteAPIEndpoint } from '@rapid/sdk/lib/api/rapid-platform.api';
import CentreRoom from '../../types/centre-room.interface';
import Centre from '../../types/centre.interface';
import Bond from '../../types/bond.interface';
import Child from '../../types/child.interface';
import Contact from '../../types/contact.interface';
import Family from '../../types/family.interface';
import Lead from '../../types/lead.interface';

type EP = ISiteAPIEndpoint;

async function getCentre(ep: ISiteAPIEndpoint, centreId: number) {
  const list = ep.Lists['Centre'];

  try {
    const result = await list.All$.Items[centreId].getJson();
    if (result) return result as Centre;
  } catch (e) {}
}

async function getCentreRooms(ep: ISiteAPIEndpoint, centreId: number) {
  const list = ep.Lists['Centre Rooms'];

  try {
    const $filter = `centre_id eq '${centreId}'`;
    const result = await list.All$.Items.getJson({ $filter });
    if (result.value) return result.value as Array<CentreRoom>;
  } catch (e) {}
}

async function getBondsFromLead(ep: EP, centreId: number, leadId: number) {
  const list = ep.Lists['Bonds'];

  try {
    const $filter = `centre_id eq '${centreId}' and lead_id eq '${leadId}'`;
    const result = await list.All$.Items.getJson({ $filter });
    if (result.value) return result.value as Array<Bond>;
  } catch (e) {}

  return null;
}

async function getChild(ep: EP, childId?: number) {
  const list = ep.Lists['Child'].All;
  if (!childId) return null;

  try {
    const result = await list.Items[childId].getJson();
    if (result) return result as Child;
  } catch (e) {}

  return null;
}

async function getFamily(ep: EP, familyId?: number) {
  const list = ep.Lists['Families'].All;
  if (!familyId) return null;

  try {
    const result = await list.Items[familyId].getJson();
    if (result) return result as Family;
  } catch (e) {}

  return null;
}

async function getPrimaryContact(ep: EP, primaryContactId?: number) {
  const list = ep.Lists['Contact'].All;
  if (!primaryContactId) return null;

  try {
    const result = await list.Items[primaryContactId].getJson();
    if (result) return result as Contact;
  } catch (e) {}

  return null;
}

export const returnRapidResponse = <T>(r: any): T => {
  if (Array.isArray(r)) {
    return r[0];
  }

  if (r?.value && !Array.isArray(r?.value)) return r.value;
  return r;
};

export const updateLead = async (ep: EP, lead: Partial<Lead>): Promise<Lead> => {
  const result = await ep.Lists['Leads'].All.Items[lead.id!].putJson(undefined, lead);
  return returnRapidResponse<Lead>(result);
};

export const updateContact = (ep: EP, contact: Partial<Contact>, familyId: number) => {};

export const updateFamily = (ep: EP, family: Partial<Family>, contactFullName: string) => {};

export { getCentre, getCentreRooms, getPrimaryContact, getBondsFromLead, getChild, getFamily };
