import { APIEndpoint } from '@rapid/sdk';
import { ISiteAPIEndpoint } from '@rapid/sdk/lib/api/rapid-platform.api';
import { TSchedulerTour } from '../Types/SchedulerTypes';
import { linkedFamilyTag } from '../Utils/SchedulerSymbols';
import createTour from './createTour';

export default async function rescheduleTour(
  ep: ISiteAPIEndpoint,
  tour: TSchedulerTour,
  centreId: number,
  omniEndpoit: APIEndpoint<any>,
  cmEmail: string,
) {
  const hasFamily = !!tour[linkedFamilyTag]?.id;

  // Check if the event has a linked family available
  if (!hasFamily) {
    const family = await (ep.Lists.Families.All$ as any).items.getJson({
      linkedTo: `Tours/${tour.id}`,
    });

    tour[linkedFamilyTag] = family.value[0];
  }

  await ep.Lists.Tours.items[tour.id].put(
    {},
    {
      tour_status: 'Rescheduled',
    },
  );

  // Fetch linked meeting event
  const meetingEventResp = await ep.Lists['Meeting Events'].All.Items.getJson({
    linkedTo: `Tours/${tour.id}`,
    $select: 'id, event_id',
  });

  const meetingEventId = meetingEventResp.value[0].id;
  const outlookEventId = meetingEventResp.value[0].event_id;

  // Delete linked meeting event
  ep.Lists['Meeting Events'].All.Items[meetingEventId].delete();

  // Manually delete outlook event because omni is too slow...
  await omniEndpoit.services['rapid-meetings'].event.outlook[cmEmail][outlookEventId]
    .delete()
    .catch((err: any) => {
      console.log(err);
    });

  // Set the tour.rebooked_tour
  tour.rebooked_tour = true;

  // Clone the tour with the updated time
  if (!!tour[linkedFamilyTag]?.id) {
    const createdTour = await createTour(ep, tour, centreId);
    return createdTour.tour;
  }
}
