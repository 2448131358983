import { AnyListItem } from '@rapid/data-model';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ILead } from '../../../Types/ItemTypes';
import { TSchedulerTour } from '../../../Types/SchedulerTypes';
import { linkedLeadsTag } from '../../../Utils/SchedulerSymbols';
import LeadCard from '../Lead/LeadCard';
import { VariableSizeList as List } from 'react-window';
import './TourLinkedLeads.scss';

interface ITourLinkedLeadsProps {
  event?: Partial<TSchedulerTour>;
  loadingLeads?: boolean;
}

export default function TourLinkedLeads({
  event,
  loadingLeads,
}: ITourLinkedLeadsProps) {
  const history = useHistory();

  const onSelectLead = (lead: AnyListItem<ILead>) => {
    if (!!lead.id) {
      const url = `${history.location.pathname}/lead-${lead.id}`;

      window.open(url, 'blank');
    }
  };

  const LeadRow = ({ index, style }) => {
    return (
      <div
        className={`Lead${index % 2 === 0 ? ' even' : ' odd'}`}
        key={event?.[linkedLeadsTag]?.[index].id}
        style={style}
      >
        <LeadCard
          key={event?.[linkedLeadsTag]?.[index].id}
          lead={event?.[linkedLeadsTag]?.[index]}
          onSelectLead={onSelectLead}
          compact
        />
      </div>
    );
  };

  const length = event?.[linkedLeadsTag]?.length ?? 0;

  return (
    <div className="TourLinkedLeads">
      <div className="Heading">
        <h3>Child{length > 1 ? 'ren' : ''}</h3>
      </div>

      {!loadingLeads && (
        <List itemSize={() => 40} width={400} height={100} itemCount={length}>
          {LeadRow}
        </List>
      )}

      {!loadingLeads && !length && (
        <div className="NoLeads">No Linked Leads</div>
      )}

      {!!loadingLeads && (
        <div className="LoadingLeads">
          <i className="fal fa-fw fa-spinner-third fa-spin" />
          &ensp;Loading linked leads...
        </div>
      )}
    </div>
  );
}
