import useComposedClassName from '@rapid-platform/use-composed-class-name';
import React from 'react';

interface IToolbarButtonProps {
  disabled?: boolean;
  onClick?(): void;
  className?: string;
  danger?: boolean;
}

export default function ToolbarButton({
  disabled,
  className,
  onClick,
  children,
  danger,
}: React.PropsWithChildren<IToolbarButtonProps>) {
  const toolbarButtonClass = useComposedClassName(
    function* () {
      yield 'ToolbarButton';

      if (disabled) yield 'disabled';

      if (className) yield className;

      if (danger) yield 'danger';
    },
    [disabled, className, danger],
  );

  return (
    <div onClick={onClick} className={toolbarButtonClass}>
      {children}
    </div>
  );
}
