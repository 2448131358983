import { DateTime } from 'luxon';
import { IOutlookEvent } from '../Types/ItemTypes';
import { TSchedulerTour } from '../Types/SchedulerTypes';
import checkBlockerCollision from './checkBlockerCollision';
import checkEventCollision from './checkEventCollision';

export default function checkCollision(
  event: TSchedulerTour | DateTime,
  events: TSchedulerTour[],
  blockers: IOutlookEvent[],
) {
  let date: DateTime;

  if (event instanceof DateTime) {
    date = event;
  } else {
    date = DateTime.fromJSDate(event.start_date);
  }

  const isLess =
    date.toSeconds() < DateTime.now().setZone(date.zone).toSeconds();

  if (isLess) {
    return true;
  }

  const eventCollision = checkEventCollision(events, date);
  const blockingCollision = checkBlockerCollision(blockers, date);

  return !!eventCollision || !!blockingCollision;
}
