export const cellHtml = (day: number, days: string[]) =>
  `<div
    class="MarkedCell cell ${days[day]}"
    onDblClick="(
      function() { 
        Framework.actionRegistry.dispatchAction(
          'empty-cell-click', 
          { day: ${day} } 
        ); 
      } 
    )();" 
  >
  </div>`;

export const rescheduleHtml = (day: number, days: string[]) =>
  `<div 
    class="MarkedCell reschedule ${days[day]}" 
    onClick="(
      function() { 
        Framework.actionRegistry.dispatchAction(
          'empty-cell-click', 
          { day: ${day} }
        ); 
      } 
    )();" 
  >
  </div>`;
