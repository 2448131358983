import '@fortawesome/fontawesome-pro/css/all.min.css';
import { Framework } from '@rapid/adaptive-framework';
import 'antd/dist/antd.css';
import App from './app';
import './App.scss';
import './Components/Controls/DebugControl/Debug.scss';
import debugControl from './Components/Controls/DebugControl/debugControl';
import './Media/scss/guardian.scss';
import './Media/scss/layout/_all.scss';
import './styles/index.scss';

// @ts-ignore-next-line
globalThis.__VERSION__ = __VERSION__;

Framework.controlRegistry.add('Default', debugControl);
Framework.render(App, 'root');
