import useComposedClassName from '@rapid-platform/use-composed-class-name';
import { AnyListItem } from '@rapid/data-model';
import React from 'react';
import { IContact, ILead } from '../../../Types/ItemTypes';
import { IContactFilter } from '../Contact/ContactSelection';

interface IMessage {
  title: React.ReactNode;
  subTitle: React.ReactNode;
  icon: string;
  className: string;
  show: boolean;
}

export function useLeadSelectionMessages(
  leadId?: number,
  lead?: AnyListItem<ILead> | null,
  leadsLoading?: boolean,
  deps?: React.DependencyList,
): IMessage {
  const initial = !leadId && !leadsLoading && !lead && lead !== null;

  const loadingLeads = !!leadsLoading;

  const withTerm = !!leadId && !leadsLoading && !lead && lead !== null;

  const noLeadsFound = !!leadId && !leadsLoading && lead === null;

  const title = useComposedClassName(function* () {
    if (initial) yield 'Search for a lead by ID.';
    if (withTerm) yield 'Press enter to search leads.';
    if (loadingLeads) yield 'Loading leads.';
    if (noLeadsFound) yield 'No leads found.';
  }, deps);

  const subTitle = useComposedClassName(function* () {
    if (withTerm) yield 'Or escape to cancel and clear search box.';
    if (loadingLeads || noLeadsFound)
      yield 'Press escape to cancel search and clear search box.';
    if (noLeadsFound)
      yield 'Press escape to cancel search and clear search box and search again using lead id from explorer.';
  }, deps);

  const icon = useComposedClassName(function* () {
    if (initial) yield 'fad fa-fw fa-child';
    if (withTerm) yield 'fad fa-fw fa-magnifying-glass';
    if (loadingLeads) yield 'fad fa-fw fa-spinner-third fa-spin';
    if (noLeadsFound) yield 'fad fa-fw fa-face-frown';
  }, deps);

  const className = useComposedClassName(function* () {
    if (initial) yield 'NoTerm';
    if (withTerm) yield 'HasTerm';
    if (loadingLeads) yield 'Loading';
    if (noLeadsFound) yield 'NoLeadsFound';
  }, deps);

  return {
    title,
    subTitle,
    icon,
    className,
    show: loadingLeads || initial || withTerm || noLeadsFound || false,
  };
}

export function useContactLeadSelectionMessages(
  leads?: AnyListItem<ILead>[],
  leadsLoading?: boolean,
  contact?: AnyListItem<IContact>,
  deps?: React.DependencyList,
): IMessage {
  const loadingLeads = !!leadsLoading && !leads?.length;

  const noLeads = !leadsLoading && !leads?.length;

  const title = useComposedClassName(function* () {
    if (loadingLeads) yield 'Loading linked leads.';
    if (noLeads) yield `No leads for ${contact?.full_name}.`;
  }, deps);

  const subTitle = useComposedClassName(function* () {
    if (loadingLeads) yield 'Hold tight...';
  }, deps);

  const icon = useComposedClassName(function* () {
    if (loadingLeads) yield 'fad fa-fw fa-spinner-third fa-spin';
  }, deps);

  const className = useComposedClassName(function* () {
    if (loadingLeads) yield 'Loading';
    if (noLeads) yield 'NoLeads';
  }, deps);

  return {
    title,
    subTitle,
    icon,
    className,
    show: loadingLeads || noLeads || false,
  };
}

export function useContactSelectionMessages(
  contact?: AnyListItem<IContact>,
  contactLoading?: boolean,
  filter?: IContactFilter,
  contactList?: AnyListItem<IContact>[],
  deps?: React.DependencyList,
): IMessage {
  const initial =
    !contact && !contactList && !contactLoading && !filter?.mobile;

  const withTerm =
    !contact && !contactList && !!filter?.mobile && !contactLoading;

  const loadingContacts = !contact && !contactList && !!contactLoading;

  const noContacts =
    !contact && !!contactList && !contactList.length && !contactLoading;

  const title = useComposedClassName(function* () {
    if (initial) yield 'Search for a contact to see children.';
    if (withTerm) yield 'Press enter to search contacts.';
    if (loadingContacts) yield 'Loading contacts.';
    if (noContacts) yield 'No contacts found.';
  }, deps);

  const subTitle = useComposedClassName(function* () {
    if (initial)
      yield 'Please search for contacts using e-mail and phone number.';
    if (withTerm) yield 'Or escape to cancel and clear search box.';
    if (loadingContacts || noContacts)
      yield 'Press escape to cancel search and clear search box.';
  }, deps);

  const icon = useComposedClassName(function* () {
    if (initial) yield 'fad fa-fw fa-user-circle';
    if (withTerm) yield 'fad fa-fw fa-magnifying-glass';
    if (loadingContacts) yield 'fad fa-fw fa-spinner-third fa-spin';
    if (noContacts) yield 'fad fa-fw fa-face-frown';
  }, deps);

  const className = useComposedClassName(function* () {
    if (initial) yield 'NoTerm';
    if (withTerm) yield 'HasTerm';
    if (loadingContacts) yield 'Loading';
    if (noContacts) yield 'NoContacts';
  }, deps);

  return {
    title,
    subTitle,
    icon,
    className,
    show: initial || withTerm || loadingContacts || noContacts || false,
  };
}
