import { ActionEvent, actionRegistry } from '@rapid/adaptive-framework';
import { AnyListItem } from '@rapid/data-model';
import { IMarkedTimespan } from '@rapid/dhtmlx-scheduler';
import {
  APIEndpoint,
  Updater,
  useCurrentSiteEndpoint,
  useListItemContext,
  useOmnichannelEndpoint,
  useRapidAuthenticatedFetch,
} from '@rapid/sdk';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { IMeetingEvent, IOutlookEvent } from '../Types/ItemTypes';
import { ISchedulerContext } from '../Types/SchedulerTypes';
import getNextLinkRecursive from '../Utils/getNextLinkRecursive';
import OffsetTime from '../Utils/offsetTime';

const STARTOFDAYMINUTES = 0;
const ENDOFDAYMINUTES = 1440;
const MIDNIGHTSTRING = '12:00 AM';

export default function useCentreSchedule(
  startOfWeek: DateTime,
  isAdmin: boolean | undefined,
  cm: AnyListItem | undefined,
  updateContext: Updater<ISchedulerContext>,
  timezone?: string,
): [
  IMarkedTimespan[] | undefined,
  IOutlookEvent[] | undefined,
  boolean,
  () => void,
  (id: string) => void,
  (id: string, patch: Record<string, any>) => Promise<void>,
] {
  const [loading, setLoading] = useState(true);

  const [schedule, setSchedule] = useState<IOutlookEvent[]>();

  const [centre] = useListItemContext();

  const [context] = useListItemContext();

  const [timespans, setTimespans] = useState<IMarkedTimespan[]>([]);

  const siteEp = useCurrentSiteEndpoint();

  useEffect(function getCMTask() {
    if (!!centre.item?.cm_task_id) {
      siteEp.Lists.Principals.items[centre.item?.cm_task_id]
        .getJson({})
        .then((res: AnyListItem) =>
          updateContext(d => {
            d.cm = res;
          }),
        )
        .catch((err: any) => {
          console.log(err);
        });
    }
  }, []);

  const omniEndpoit = useOmnichannelEndpoint();

  const meetingEventsEp = siteEp.Lists['Meeting Events'].All$
    .Items as any as APIEndpoint;

  const calViewEp =
    omniEndpoit.services['rapid-meetings'].event.outlook[cm?.email ?? cm?.upn]
      .calendarView;

  const authFetch = useRapidAuthenticatedFetch();

  const sow = startOfWeek.toUTC().toISO();
  const eow = startOfWeek.toUTC().plus({
    days: 5,
    hours: 10,
  });

  async function fetchAvailability() {
    setLoading(true);
    const outlookP = getNextLinkRecursive<IOutlookEvent>(calViewEp, authFetch, {
      startDateTime: sow,
      endDateTime: eow,
      $top: "1000",
      $select: 'start,end,responseStatus,isCancelled,subject',
    });
    const meetingEventsP = getNextLinkRecursive<AnyListItem<IMeetingEvent>>(
      meetingEventsEp,
      authFetch,
      {
        $filter: `start gt '${sow}' and end lt '${eow}'`,
        linkedTo: `Centres/${context.item?.id}`,
      },
    );
    const [outlook, meetingEvents] = await Promise.all([
      outlookP,
      meetingEventsP,
    ]);

    updateContext(d => {
      d.meetingEvents = meetingEvents;
      d.outlookEvents = outlook;
    });

    const blockers = outlook.reduce(
      (prev: IOutlookEvent[], curr: IOutlookEvent) => {
        if (curr.isCancelled || curr.responseStatus.response === 'declined' || meetingEvents.some((me: AnyListItem) => me.event_id === curr.id)) {
          return prev;
        }
        prev.push(curr);
        return prev;
      },
      [],
    );
    setSchedule(blockers);
  }

  const deleteEvent = (id: string) => {
    setLoading(true);

    omniEndpoit.services['rapid-meetings'].event.outlook[cm?.email ?? cm?.upn][
      id
    ]
      .delete()
      .then(() => {
        fetchAvailability();
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const updateEvent = async (id: string, patch: Record<string, any>) => {
    if (!patch.start.dateTime || !patch.end.dateTime) {
      return;
    }

    setLoading(true);

    patch.start.dateTime = OffsetTime(DateTime.fromISO(patch.start.dateTime), timezone, 'Local to Zone').toUTC().toISO();
    patch.end.dateTime = OffsetTime(DateTime.fromISO(patch.end.dateTime), timezone, 'Local to Zone').toUTC().toISO();

    await omniEndpoit.services['rapid-meetings'].event.outlook[
      cm?.email ?? cm?.upn
    ][id]
      .patch({}, patch)
      .catch((err: any) => {
        console.log(err);
      });

    fetchAvailability();
  };

  useEffect(
    function getAvailabitlityOnMount() {
      if (!!cm?.email) {
        fetchAvailability();
      }
    },
    [startOfWeek, cm?.email],
  );

  useMemo(
    function buildTimespansFromSchedule() {
      if (!!schedule) {
        const timespans = schedule
          .reduce((prev, curr, ind) => {
            let startDay: number;
            let endDay: number;
            let start: number;
            let end: number;

            const startDt = DateTime.fromISO(curr.start.dateTime, {
              zone: curr.start.timeZone,
            }).setZone(timezone);

            const endDt = DateTime.fromISO(curr.end.dateTime, {
              zone: curr.end.timeZone,
            }).setZone(timezone);

            const endOfWeek = startOfWeek.endOf('week');

            startDay = startDt.weekday === 7 ? 0 : startDt.weekday;
            endDay = endDt.weekday === 7 ? 0 : endDt.weekday;
            start = startDt.hour * 60 + startDt.minute;
            end = endDt.hour * 60 + endDt.minute;

            if (startDay !== endDay) {
              let startString = startDt.toFormat('hh:mm a');
              let endString = endDt.toFormat('hh:mm a');

              if (startDt.toMillis() < startOfWeek.toMillis()) {
                startDay = 0;
                start = 0;
                startString = MIDNIGHTSTRING;
              }

              if (endDt.toMillis() > endOfWeek.toMillis()) {
                endDay = 6;
                end = ENDOFDAYMINUTES;
                endString = MIDNIGHTSTRING;
              }

              const dayDiff = endDay - startDay;
              for (let i = 0; i <= dayDiff; i++) {
                prev.push({
                  days: startDay + i,
                  zones: [
                    i === 0 ? start : STARTOFDAYMINUTES,
                    i === dayDiff ? end : ENDOFDAYMINUTES,
                  ],
                  css: 'gray_section',
                  type: 'dhx_time_block',
                  event: curr,
                  html: blockingHTML(
                    curr.subject,
                    i === 0 ? startString : MIDNIGHTSTRING,
                    i === dayDiff ? endString : MIDNIGHTSTRING,
                    curr.id,
                    !!isAdmin,
                  ),
                } as IMarkedTimespan);
              }
            } else {
              prev.push({
                days: startDay,
                zones: [start, end],
                css: 'gray_section',
                type: 'dhx_time_block',
                event: curr,
                html: blockingHTML(
                  curr.subject,
                  startDt.toFormat('hh:mm a'),
                  endDt.toFormat('hh:mm a'),
                  curr.id,
                  !!isAdmin,
                ),
              } as IMarkedTimespan);
            }

            return prev;
          }, [] as IMarkedTimespan[]);

        setTimespans(timespans);
        setLoading(false);
      }
    },
    [schedule],
  );

  const handleAction = (e: ActionEvent) => {
    const blocker = schedule?.[e.args?.index];

    if (!!blocker?.id) {
      deleteEvent(blocker.id);
    }
  };

  useEffect(
    function catchBlockerActionsEffect() {
      actionRegistry.addEventListener(
        'cancel-blocker',
        handleAction as EventListener,
      );

      return function cleanupBlockerActions() {
        actionRegistry.removeEventListener(
          'cancel-blocker',
          handleAction as EventListener,
        );
      };
    },
    [schedule],
  );

  return [
    timespans,
    schedule,
    loading,
    fetchAvailability,
    deleteEvent,
    updateEvent,
  ];
}

const blockingHTML = (
  subject: string,
  startDate: string,
  endDate: string,
  blockerId: string,
  isAdmin: boolean,
) => {
  if (isAdmin) {
    return `<span title="Blocked by: ${subject} \n\nfrom: ${startDate} \n\nto: ${endDate} \n\nDouble click to edit" class="BlockingEvent" onDblClick="(function(e) { Framework.actionRegistry.dispatchAction('open-lightbox', { mode: 'edit-blocking', blockerId: '${blockerId}' } );})();"  ><div class="Subject">${subject}</div><div class="Time">${startDate} - ${endDate}</div></span>`;
  } else {
    return `<span title="Blocked by: ${subject} \n\nfrom: ${startDate} \n\nto: ${endDate}" class="BlockingEvent" ><div class="Subject">${subject}</div><div class="Time">${startDate} - ${endDate}</div></span>`;
  }
};

// Remove Blocking Button
// <button class="RemoveBlockingButton" onClick="(function(e) { Framework.actionRegistry.dispatchAction('open-lightbox', { mode: 'remove-blocking', blockerId: '${blockerId}' });})();" ><i class="fal fa-trash-can fa-fw" ></i>&ensp;Remove blocker</button>
