import React from 'react';
import { useViewContextListItemArray } from '@rapid/sdk';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import maxBy from 'lodash/maxBy';

export default function TourDateTime() {
  const [tours] = useViewContextListItemArray();

  const tourBookingDateAndTime = useMemo(
    () =>
      DateTime.fromISO(
        maxBy(tours, 'tour_booking_date_and_time')?.tour_booking_date_and_time,
      ),
    [tours],
  );

  return (
    <>
      <span>
        {tourBookingDateAndTime?.isValid &&
          tourBookingDateAndTime.toLocaleString(DateTime.DATE_SHORT)}
      </span>
      <small>
        &nbsp;(
        {tourBookingDateAndTime?.isValid && tourBookingDateAndTime.toRelative()}
        )
      </small>
    </>
  );
}
