import { IField } from '@rapid/data-model';
import { useComposedClassName } from '@rapid/sdk';
import { Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import React, { useMemo } from 'react';
import './dropdown-field.scss';

interface IDropdownFieldProps {
  label: string;
  field: IField;
  error?: string;
  className?: string;
  id?: string;
  onChange: (value: string) => void;
  value?: string;
  required?: boolean;
  [key: string]: unknown;
}

function DropdownField({
  className,
  label,
  field,
  error,
  id,
  required,
  onChange,
  value,
  ...rest
}: IDropdownFieldProps) {
  const cn = useComposedClassName(
    function* () {
      yield 'field-container';
      if (className) yield className;
    },
    [className],
  );

  const options: DefaultOptionType[] = useMemo(() => {
    if (!field.Settings || !field.Settings.Choices) return [];

    return field.Settings.Choices.map(choice => ({
      label: typeof choice === 'string' ? choice : choice.Text,
      value: typeof choice === 'string' ? choice : choice.Value ?? choice.Text,
    }));
  }, [field.Settings]);

  return (
    <div className={cn} data-required={!!required} data-invalid={!!error}>
      <label htmlFor={id} className="field-container__label">
        {label}
      </label>

      <Select
        className="dropdown-field"
        id={id}
        options={options}
        onSelect={onChange}
        value={value}
        allowClear={!required}
        onBlur={rest.onBlur as any}
      />

      <p className="field-container__error" id={`${id}-error`}>
        {error}
      </p>
    </div>
  );
}

export default DropdownField;
