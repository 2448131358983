import * as React from 'react';
import './Footer.scss';

export interface IFooterProps {}

function Footer(props: IFooterProps) {
  return (
    <div style={{ position: 'relative' }} className="gce-margin-medium-top">
      <div className="gce-container">
        <div className="gce-site-footer gce-text-small">
          <div className="gce-footer-left"></div>
          <div className="gce-footer-center">
            <div className="gce-text-center">
              <a href="tel:1300001119">
                <i className="fas fa-phone"></i> 1300 001 119
              </a>
              <br />© Copyright Guardian Childcare and Education 2020
            </div>
          </div>
          <div className="gce-footer-right"></div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
