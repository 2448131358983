import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { ManagedListItemContext } from '@rapid/sdk';

export default function TaskContext({ children }: React.PropsWithChildren<{}>) {
  const match = useRouteMatch<any>();

  return (
    <ManagedListItemContext validate list="Tasks" id={match.params.taskId}>
      {children}
    </ManagedListItemContext>
  );
}
