import { AnyListItem } from '@rapid/data-model';
import React from 'react';
import { IContact } from '../../../Types/ItemTypes';
import './Card.scss';

interface IContactCardProps {
  contact?: AnyListItem<IContact>;
  onContactClick?(contact?: AnyListItem<IContact>): void;
}

export default function ContactCard({
  contact,
  onContactClick,
}: IContactCardProps) {
  const onClick = () => {
    onContactClick?.(contact);
  };

  return (
    <div className="ContactCard Card" onClick={onClick}>
      <div className="LeftItems">
        <i className="fal fa-fw fa-user-circle ContactIcon Icon" />
        <div className="ContactName NameContainer">
          <div className="FullName Name" title={contact?.full_name ?? ''}>
            {contact?.full_name ?? <em className="muted">(Not set)</em>}
          </div>
          <div className="Family SubName" title={contact?.family ?? ''}>
            {contact?.family ?? <em className="muted">(Not set)</em>}
          </div>
        </div>
      </div>
      <div className="RightItems">
        <div className="Email" title={contact?.email ?? ''}>
          <i className="fal fa-envelope fa-fw EmailIcon Icon IconSmall" />
          {contact?.email ?? <em className="muted">(Not set)</em>}
        </div>
        <div className="Phone" title={contact?.home_phone ?? ''}>
          <i className="fal fa-phone fa-fw PhoneIcon Icon IconSmall" />
          {contact?.home_phone ?? contact?.mobile ?? contact?.work_phone ?? (
            <em className="muted">(Not set)</em>
          )}
        </div>
      </div>
    </div>
  );
}
