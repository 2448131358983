import { ISortClause } from '@rapid/data-model';
import React from 'react';
import { IColumn } from '../../Store';
import SortIcon from './SortIcon';

interface HeaderProps {
  columns: IColumn[];
  sortColumn?: ISortClause;
  onSort?(clause: ISortClause): void;
  isLoading?: boolean;
}

export default function TableHeader(props: HeaderProps) {
  const raiseSort = (column: IColumn) => {
    const sortCol: ISortClause = { ColumnName: column.path };

    if (sortCol.ColumnName === column.path) {
      sortCol.Direction =
        !props.sortColumn?.Direction || props.sortColumn.Direction === 'asc' ? 'desc' : 'asc';
    } else {
      sortCol.ColumnName = column.path;
      sortCol.Direction = 'asc';
    }

    if (column.invertSort) {
      sortCol.Direction = props.sortColumn?.Direction === 'asc' ? 'desc' : 'asc';
    }

    props.onSort?.(sortCol);
  };

  return (
    <tr className="rapid-header-item">
      {props.columns.map(column => (
        <th
          key={`th-${column.title ?? column.path}`}
          style={{
            fontWeight: column.path !== props.sortColumn?.ColumnName ? 'inherit' : 'bold',
            ...column.css,
          }}
        >
          {column.label ? (
            <a className="sort" data-sort="">
              <span onClick={() => raiseSort(column)}>
                {column.iconName && <i className={`fal fa-fw ${column.iconName}`}></i>}{' '}
                {column.label}
                &ensp;
                <span className="sort-icon">
                  {props.isLoading && column.path === props.sortColumn?.ColumnName && (
                    <i className="fa fa-spinner fa-spin"></i>
                  )}
                  {!props.isLoading && <SortIcon column={column} clause={props.sortColumn!} />}
                </span>
              </span>
            </a>
          ) : (
            <span>{column.label}</span>
          )}
        </th>
      ))}
    </tr>
  );
}
