import { useViewContextListItemArray } from '@rapid/sdk';
import groupBy from 'lodash/groupBy';
import kebabCase from 'lodash/kebabCase';
import sortBy from 'lodash/sortBy';
import React, { useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import CentreListItem from './CentreListItem';
import Breadcrumb from './common/Breadcrumb';
import SearchBox from './common/SearchBox';

export default function CentreList() {
  const [searchKey, setSearchKey] = useState('');
  const [centres] = useViewContextListItemArray();
  const history = useHistory();

  const centresByState = useMemo(
    () =>
      groupBy(
        sortBy(
          centres.filter(
            i =>
              !searchKey ||
              i.centre_alias
                ?.toLocaleLowerCase()
                .includes(searchKey?.toLocaleLowerCase())
          ),
          'centre_alias'
        ),
        'state'
      ),
    [centres, searchKey]
  );

  function onCentreSearch() {
    const entries = Object.entries(centresByState);
    if (entries.length === 1 && entries[0][1].length === 1) {
      const [centre] = entries[0][1];

      history.push(`/${centre.id}-${kebabCase(centre.centre_alias)}`);
    }
  }

  return (
    <>
      <Breadcrumb breadcrumbs={[{ displayName: 'Centres' }]} />

      <div className="gce-container gce-margin-medium-top">
        {/* <h1 className="gce-text-center">
          <i className="fad fa-home" /> Centres
        </h1> */}

        <section
          id="centreContainer"
          className="rapid-list gce-component-container"
        >
          <SearchBox
            placeholder="Search Centre..."
            onChange={setSearchKey}
            onSearch={onCentreSearch}
            value={searchKey}
          />

          <div className="rapid-list-items">
            {Object.entries(centresByState).map(([state, centres]) => (
              <React.Fragment key={state}>
                <h3 className="rapid-list-headeritem gce-text-center">
                  {state}
                </h3>

                <div className="rapid-list-group">
                  {centres.map(centre => (
                    <Link
                      key={centre.id}
                      className="rapid-list-item"
                      to={`${centre.id}-${kebabCase(centre.centre_alias)}`}
                    >
                      <CentreListItem centre={centre} />
                    </Link>
                  ))}
                </div>
              </React.Fragment>
            ))}
          </div>
        </section>
      </div>
    </>
  );
}
