import { IColumnProps } from '../../Store';
import React from 'react';

export default function ParentChildCell({ item }: IColumnProps) {
  const parentName = item.parent_name || item.family || item.lead_name || '';
  const childName = item.child || item.child_name || 'TBC';

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: '140px',
        maxWidth: '150px',
        textAlign: 'left',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          margin: 0,
          fontWeight: 400,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
        title={parentName}
      >
        {parentName}
      </div>
      <div
        style={{
          margin: 0,
          fontSize: '0.8rem',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
        title={childName}
      >
        {childName}
      </div>
    </div>
  );
}
