import { APIEndpoint, WindowFetch } from '@rapid/sdk';

const ODATA_NEXT = '@odata.nextLink';

export default async function getNextLinkRecursive<T>(
  endPoint: APIEndpoint,
  authFetch: WindowFetch,
  query?: Record<string, any> | string,
): Promise<T[]> {
  let val: T[] = [];

  let nextLink: string;

  if (typeof query === 'string') {
    const nextResp = await authFetch(query);
    const next = await nextResp.json();
    val = val.concat(next.value);
    nextLink = next[ODATA_NEXT];
  } else {
    const resp = await endPoint.getJson(query);
    val = val.concat(resp.value);
    nextLink = resp[ODATA_NEXT];
  }

  if (!!nextLink) {
    const next: T[] = await getNextLinkRecursive<T>(
      endPoint,
      authFetch,
      nextLink,
    );

    val = val.concat(next);
  }

  return val;
}
