import { Button } from 'antd';
import React from 'react';
import { TSchedulerTour } from '../../../Types/SchedulerTypes';
import Message from '../Message/Message';
import './ConfirmCancel.scss';

interface IConfirmRescheduleProps {
  event?: Partial<TSchedulerTour>;
  onRescheduleTour(): void;
}

export default function ConfirmReschedule({
  event,
  onRescheduleTour,
}: IConfirmRescheduleProps) {
  return (
    <div className="ConfirmCancel">
      <Message
        title={
          <span>
            {`Are you sure you want to reschedule`}{' '}
            <strong className="EventTitle">{event?.text}</strong>
          </span>
        }
        subTitle={
          'This will send a cancellation to the family for the original tour and an updated invitation link with the new time'
        }
        icon={'fad fa-fw fa-triangle-exclamation'}
      />
      <div className="ConfirmCancelActions">
        <Button
          danger
          type="primary"
          onClick={onRescheduleTour}
          className="CRUDButton Reschedule"
        >
          Reschedule Tour
        </Button>
      </div>
    </div>
  );
}
