import { IField } from '@rapid/data-model';
import {
  Spinner,
  useChoices,
  useCurrentSiteEndpoint,
  useList,
  useListItemContext,
} from '@rapid/sdk';
import { Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getCentre,
  getCentreRooms,
  getChild,
  getFamily,
  getPrimaryContact,
  returnRapidResponse,
} from '../../common/utils/requests';
import CentreRoom from '../../types/centre-room.interface';
import Centre from '../../types/centre.interface';
import Child from '../../types/child.interface';
import Contact from '../../types/contact.interface';
import Family from '../../types/family.interface';
import Lead from '../../types/lead.interface';
import DynamicForm from '../dynamic-form/dynamic-form';
import { FormField } from '../dynamic-form/fields/form-field';
import NoteField from '../dynamic-form/fields/note-field/note-field';

type Params = { tourId: string; leadId: string; centreId: string };

type State = {
  child?: Child | null;
  lead?: Lead | null;
  family?: Family | null;
  contact?: Contact | null;
  note?: string;
  centre?: Centre;
  centreRooms?: Array<CentreRoom>;
  updated?: Record<string, { formValue?: any; errors?: object }>;
};

const dayPickerField = {
  ColumnName: '__day_picker',
  Title: 'Days',
  FieldType: 'DayPicker' as any,
} as IField;

interface IMoveToWaitlistFormProps {
  onSubmit?(tab: string): void;
}

export default function MoveToWaitlistForm({ onSubmit }: IMoveToWaitlistFormProps) {
  const [contactList] = useList('Contacts');
  const [leadList] = useList('Leads');
  const [childList] = useList('Children');

  const params = useParams<Params>();
  const centreId = /^(\d+)/.exec(params.centreId)?.[0]!;
  const ep = useCurrentSiteEndpoint();

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [{ fetchItem: _fetchItem, fetchTimeline }] = useListItemContext();
  const [error, setError] = useState('');
  const [waitlistReason, updateWaitlistReason] = useState('');
  const [reason] = useChoices('Waitlists', 'reason');
  const [state, updateState] = useState<State>({});

  const onMount = useCallback(async lead => {
    const centre = await getCentre(ep, +centreId);
    const centreRooms = await getCentreRooms(ep, +centreId);
    const child = await getChild(ep, lead.child_id);
    const family = await getFamily(ep, lead.family_id);
    const contact = await getPrimaryContact(ep, lead.primary_contact_id);

    updateState({
      centre,
      centreRooms,
      child,
      family,
      lead,
      contact,
      note: '',
      updated: {},
    });
    setLoading(false);
  }, []);

  const hasError = Object.values(state.updated ?? {}).some((v: any) => {
    return Object.values(v?.errors ?? {}).some(err => !!err);
  });

  const onFormUpdated = (key: string) => (formValue, errors) => {
    updateState(draft => ({
      ...draft,
      updated: { ...draft.updated, [key]: { formValue, errors } },
    }));
  };

  useEffect(() => {
    ep.Lists.Leads.All.Items[+params.leadId].getJson().then(lead => {
      onMount(lead);
    });
  }, []);

  const onNoteUpdate = (event: any) => {
    updateState(draft => {
      draft.note = event?.target?.value;
      return { ...draft };
    });
  };

  const onWaitlistReason = (value: string) => {
    updateWaitlistReason(value);
  };

  const onSubmitForm = async () => {
    setSubmitting(true);
    setError('');
    const leadEp = ep.Lists.Leads;
    const childEp = ep.Lists.Children;
    const familyEp = ep.Lists.Families;
    const contactEp = ep.Lists.Contacts;

    let lead = state.updated!.lead.formValue as Lead;
    let child = (state.updated!.child?.formValue as Child) ?? {};
    let contact = (state.updated!.contact?.formValue as Contact) ?? {};
    let family = (state.updated!.family?.formValue as Family) ?? {};

    const hasChild = Object.keys(child).length > 0;

    if (!waitlistReason) {
      setError('This Field is required!');
      setSubmitting(false);
      return;
    }

    if (!lead.id) return;
    const waitListItem = await ep.Lists.Waitlist.Items.postJson(undefined, {
      LinkedItemsToAdd: [`lead/${lead.id}`],
      reason: waitlistReason,
      lead_id: lead.id,
      status: 'Open',
      centre_id: centreId,
    });

    const tours = await ep.Lists.Tour.All.Items.getJson({
      linkedTo: `Leads/${lead.id}`,
      $filter: `centre_id eq ${centreId}`,
    });

    for (const tour of tours?.value ?? []) {
      await ep.Lists.Tour.Items[tour.id].putJson(undefined, {
        on_waitlist: true,
      });
    }

    (lead as any).LinkedItemsToAdd = [`waitlists/${waitListItem[0].id}`];
    const updatedLead = await leadEp.Items[lead.id].putJson(undefined, lead);
    lead = returnRapidResponse(updatedLead);

    if (contact.id) {
      contact = returnRapidResponse(await contactEp.Items[contact.id].putJson(undefined, contact));
    } else {
      contact = returnRapidResponse(await contactEp.Items.postJson(undefined, contact));
    }

    family.family_name =
      contact.full_name ?? `${contact.first_name ?? ''} ${contact.last_name ?? ''}`;

    if (family.id) {
      family = returnRapidResponse(await familyEp.Items[family.id].putJson(undefined, family));
    } else {
      family = returnRapidResponse(await familyEp.Items.postJson(undefined, family));
    }

    contact.family_id = family.id;
    contact = returnRapidResponse(await contactEp.Items[contact.id].putJson(undefined, contact));

    if (hasChild) {
      child.family_id = family.id;

      if (child.id) {
        child = returnRapidResponse(await childEp.Items[child.id].putJson(undefined, child));
      } else {
        child = returnRapidResponse(await childEp.Items.postJson(undefined, child));
      }

      lead.child_id = child.id;
    }

    lead.family_id = family.id;
    lead.primary_contact_id = contact.id;
    await ep.Lists.Leads.Items[lead.id].putJson(undefined, lead);

    if (state.note) {
      const linkedItemsToAdd: string[] = [];
      const inheritedLinksToadd: string[] = await ep.Lists.Leads.Items[lead.id][
        'inherited-links'
      ].getJson({ type: 'Notes' });

      if (inheritedLinksToadd?.length) linkedItemsToAdd.push(...inheritedLinksToadd);

      linkedItemsToAdd.push(`lead/${lead.id}`);

      if (params.tourId) linkedItemsToAdd.push(`tour/${params.tourId}`);

      const noteData = { LinkedItemsToAdd: linkedItemsToAdd, body: state.note };

      await ep.Lists.Notes.Items.postJson(undefined, noteData);
    }

    setSubmitting(false);
    _fetchItem();
    fetchTimeline?.(true);
    onSubmit?.('waitlist');
  };

  if (loading || !state.lead) {
    return <Loader />;
  }

  return (
    <div className="gce-component-container gce-margin-medium-top">
      {submitting && <Loader />}

      <div className="gce-grid gce-margin">
        <div className="gce-width-1-1 gce-margin-top">
          <h3>Contact details</h3>
        </div>
        <DynamicForm
          fields={contactList.Fields ?? []}
          initialValue={state.contact! ?? {}}
          onUpdate={onFormUpdated('contact')}
        >
          <FormField name="first_name" className="gce-width-1-2@m" required />
          <FormField name="last_name" className="gce-width-1-2@m" required />
          <FormField name="mobile" className="gce-width-1-2@m" required />
          <FormField name="email" className="gce-width-1-2@m" required />
        </DynamicForm>
      </div>

      <div className="gce-grid gce-margin">
        <div className="gce-width-1-1 gce-margin-top">
          <h3>Child details</h3>
        </div>
        <DynamicForm
          fields={childList.Fields ?? []}
          initialValue={state.child! ?? {}}
          onUpdate={onFormUpdated('child')}
        >
          <FormField name="first_name" className="gce-width-1-2@m" />
          <FormField name="last_name" className="gce-width-1-2@m" />
          <FormField name="dob" label="Date of birth" className="gce-width-1-2@m" />
          <FormField name="gender" className="gce-width-1-2@m" />
        </DynamicForm>
      </div>

      <div className="gce-grid gce-margin">
        <div className="gce-width-1-1 gce-margin-top">
          <h3>Care details</h3>
        </div>
        <DynamicForm
          fields={[...leadList.Fields, dayPickerField]}
          initialValue={state.lead ?? {}}
          onUpdate={onFormUpdated('lead')}
        >
          <FormField name="start_date" className="gce-width-1-4@m" />
          <FormField name="total_days" className="gce-width-1-4@m" />
          <FormField name="__day_picker" className="gce-width-1-4@m" />
        </DynamicForm>
      </div>

      <div className="gce-grid gce-margin">
        <div className="gce-width-1-1">
          <label htmlFor="waitlist_item" className="gce-form-label">
            Reason?
          </label>
          <span className="required">
            <span className="gce-text-medium">*</span>
          </span>
          <Select
            className={`gce-input ${error ? 'input-validation-error' : ''}`}
            options={reason.map(r => ({
              value: r.Text,
            }))}
            id="waitlist_item"
            value={waitlistReason}
            onSelect={onWaitlistReason}
          />
          {error ? (
            <span className="tm-text-danger tm-text-small field-validation-error">
              <span>{error}</span>
            </span>
          ) : null}
        </div>
      </div>

      <div className="gce-grid gce-margin">
        <div className="gce-width-1-1 gce-margin-top">
          <h3>Notes</h3>
        </div>
        <NoteField
          label="Anything else we need to know?"
          id="additional_notes"
          value={state.note}
          onChange={onNoteUpdate}
        />
      </div>

      <div className="gce-grid gce-margin">
        <div className="gce-width-1-1 gce-margin-top gce-text-center">
          <button
            disabled={hasError}
            className={`gce-button gce-button-secondary ${
              !hasError ? 'gce-darkgreen' : 'gce-blue'
            }`}
            type="submit"
            onClick={onSubmitForm}
          >
            Move to Waitlist
          </button>
        </div>
      </div>
    </div>
  );
}

function Loader() {
  return (
    <div className="EditOfferSpinner" style={{ zIndex: 9999 }}>
      <div className="EditOfferSpinner__card rp-card-default">
        <h3>Loading...</h3>
        <div className="EditOfferSpinner__spin-container">
          <Spinner />
        </div>
      </div>
    </div>
  );
}
