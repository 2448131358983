import {
  UncontrolledAdaptiveRenderer,
  useAdaptiveData,
  useRapidUpdater,
} from '@rapid/adaptive-framework';
import { IElement, IEmbeddedForm, IList } from '@rapid/data-model';
import {
  embeddedToAdaptiveSchema,
  recursiveSearch,
  useList,
  useListItemContext,
} from '@rapid/sdk';
import jsonpath from 'jsonpath';
import React, { useEffect, useMemo, useState } from 'react';
import FieldError from './FieldError';
import LoadingSpinner from './Spinner/LoadingSpinner';

interface IEmbeddedFormProps {
  form: IEmbeddedForm;
}

type KFormElementTypes =
  | 'Input.Boolean'
  | 'Input.Currency'
  | 'Input.Number'
  | 'Input.WholeNumber'
  | 'Input.User'
  | 'Input.Lookup'
  | 'Input.DateTime'
  | 'Input.MultiLookup'
  | 'Input.Percentage';

function returnAdaptiveFieldType(fieldType) {
  switch (fieldType) {
    case 'Text':
      return 'Input.Text';
    case 'Email':
      return 'Input.Email';
    case 'Note':
      return 'Input.MultiLineText';
    case 'DateTime':
      return 'Input.DateTime';
    case 'Choice':
      return 'Input.Choice';
    case 'Lookup':
      return 'Input.Lookup';
    case 'Boolean':
      return 'Input.Boolean';
    case 'Number':
      return 'Input.Number';
    case 'Integer':
      return 'Input.WholeNumber';
    case 'Percentage':
      return 'Input.Percentage';
    case 'Currency':
      return 'Input.Currency';
    case 'MultiLookup':
      return 'Input.MultiLookup';
    case 'User':
      return 'Input.User';
    case 'Computed':
      return 'Input.Computed';
    case 'Subquery':
      return 'Input.Computed';
    default:
      return fieldType;
  }
}

function returnFieldTypeYupAstType(formElementType: KFormElementTypes) {
  switch (formElementType) {
    case 'Input.Boolean':
      return 'yup.boolean';
    case 'Input.Currency':
    case 'Input.Number':
    case 'Input.WholeNumber':
    case 'Input.Percentage':
    case 'Input.User':
    case 'Input.Lookup':
      return 'yup.number';
    case 'Input.DateTime':
      return 'yup.date';
    case 'Input.MultiLookup':
      return 'yup.array';
    default:
      return 'yup.string';
  }
}

function mutateForm(form: IElement, list: IList, hasItem: boolean) {
  if (form.$children) {
    for (const child of form.$children) {
      mutateForm(child, list, hasItem);
    }
  }

  if (form.$type === 'Input.Computed') {
    const field = list.Fields.find(
      ({ ColumnName }) => ColumnName === form?.attributes?.columnName,
    );
    if (
      field?.Settings?.DisplayAs &&
      !['DateTime', 'Email'].includes(field?.Settings?.DisplayAs)
    ) {
      form.$type = returnAdaptiveFieldType(field?.Settings?.DisplayAs);
      if (!form.attributes) form.attributes = {};
      form.attributes.isComputed = true;
      form.attributes.disabled = true;
    }
  }

  if (!hasItem) {
    if (!form.attributes) {
      form.attributes = { disabled: true };
    } else {
      form.attributes.disabled = true;
    }
  }
}

export default function EmbeddedForm({ form }: IEmbeddedFormProps) {
  const [
    { item, updateItem, fetchItem, loading, validationErrors },
    updateContext,
  ] = useListItemContext();

  const [list] = useList(form.FormRef.Type);

  const formSchema = useMemo(
    function useEmbeddedToAdaptiveForm() {
      const _formSchema = embeddedToAdaptiveSchema(form, list);
      mutateForm(_formSchema, list, !!item);
      return _formSchema;
    },
    [list, form, !!item],
  );

  const title = form.Title ?? `${list.ListNameSingular} Details`;

  const fields = [
    ...recursiveSearch(
      formSchema,
      '$children',
      i =>
        /^Input/.test(i.$type) &&
        !/Computed$/.test(i.$type) &&
        !/Subquery$/.test(i.$type) &&
        !/MultiLookup$/.test(i.$type),
    ),
  ];

  useEffect(
    function pushValidationOnMountEffect() {
      let index: number | undefined;

      if (!!formSchema.$children?.length) {
        updateContext(d => {
          const validationObject = fields.reduce((result, field) => {
            const type = field.$type as KFormElementTypes;

            if (field.attributes?.isComputed) return result;

            result[field.attributes?.columnName] = [
              [returnFieldTypeYupAstType(type), field.attributes?.columnName],
              ['yup.required', field.attributes?.columnName],
            ];

            return result;
          }, {});

          index = d.validation?.push([
            ['yup.object'],
            ['yup.shape', validationObject],
            ['yup.required'],
          ]);
        });
      }

      return function popValidationOnUnmountEffect() {
        if (typeof index !== 'undefined') {
          updateContext(d => {
            d.validation?.splice(index!, 1);
          });
        }
      };
    },
    [formSchema.$children?.length],
  );

  const [update, forceUpdate] = useRapidUpdater();
  const [data, , _onDataChange] = useAdaptiveData(
    item ?? {},
    formSchema,
    forceUpdate,
    'edit',
    [item, formSchema],
  );

  const onDataChange = (
    element: IElement,
    data: any,
    path?: string,
    key?: string,
  ) => {
    if (!!path) {
      updateItem(d => {
        jsonpath.value(d, path, data);
      });
    }

    _onDataChange(element, data, path, key);
  };

  useEffect(
    function fetchItemOnMount() {
      fetchItem();
    },
    [form.FormRef.Id, form.FormRef.Type],
  );

  return (
    <div className="gce-component-container gce-padding-top gce-margin-medium-top">
      <h3 className="gce-text-medium gce-text-center">{title}</h3>
      {loading && <LoadingSpinner type="component" />}
      {!!validationErrors?.length && item && (
        <div className="gce-alert-danger">
          Please fill out the following fields:
          <ul>
            {validationErrors?.map(error => (
              <FieldError
                key={`${error.list}/${error.list}`}
                list={list}
                field={error.error?.replace(/\ must be a([\s\S]*)$/, '')}
              />
            ))}
          </ul>
        </div>
      )}
      <UncontrolledAdaptiveRenderer
        mode="edit"
        schema={formSchema}
        update={update}
        data={data}
        forceUpdate={forceUpdate}
        onDataChange={onDataChange}
      />
    </div>
  );
}

// const onChange = (element: IElement, value: any) => {
//   updateItem?.(d => {
//     const v = typeof value === 'object' ? value?.value : value;
//     d[element.attributes?.columnName ?? element.id] = v;

//     if (element.$type === 'Input.DateTime') {
//       const date = value as Date;
//       d[element.attributes?.columnName ?? element.id] =
//         date?.toISOString() ?? null;
//     }

//     if (
//       value?.label &&
//       (element.$type === 'Input.MultiLookup' ||
//         element.$type === 'Input.Lookup' ||
//         element.$type === 'Input.User')
//     ) {
//       d[element.attributes?.columnName.replace(/_id$/, '')] = value.label;
//       d[element.attributes?.columnName ?? element.id] = value.value;
//       return;
//     }
//   });
// };
