import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import './Lightbox.scss';

interface ILightboxProps {}

export default function Lightbox({
  children,
}: React.PropsWithChildren<ILightboxProps>) {
  const [container, setContainer] = useState<HTMLElement>();

  useEffect(function getPortalRootOnMount() {
    const _container = document.getElementById('rp-portal-root');

    if (!!_container) {
      _container.className = 'Lightbox open';
      setContainer(_container);
    }

    return function cleanupFunction() {
      if (!!_container) _container.className = 'Lightbox closed';
    };
  }, []);

  if (!!container) {
    return ReactDOM.createPortal(children, container);
  }

  return null;
}
