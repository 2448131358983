import React from 'react';

import { IColumnProps } from '../../Store';

export default function IconCell({column}: IColumnProps) {
  const icon = column.rowIconName ?? 'fa-child';

  return (
    <>
      <span className="fa-stack" style={{ verticalAlign: 'top', width: 48 }}>
        <i className="fal fa-circle fa-stack-2x"></i>
        <i className={`fal ${icon} fa-stack-1x`}></i>
      </span>
    </>
  );
}
