import { ManagedViewContext } from '@rapid/sdk';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import LightboxContext from './Contexts/LightboxContext/LightboxContext';
import SchedulerContext from './Contexts/SchedulerContext/SchedulerContext';
import Scheduler from './Scheduler';
import './SchedulerTab.scss';

interface ISchedulerTabProps {
  centreId: number;
  timezone?: string;
}

export default function SchedulerTab({ centreId, timezone: zone }: ISchedulerTabProps) {
  const [startOfWeek, setStartOfWeek] = useState(() =>
    DateTime.now().setZone(zone).startOf('week').set({
      hour: 8,
      minute: 0,
      second: 0,
    }),
  );

  const endOfWeek = startOfWeek.plus({ days: 5 }).set({ hour: 17, minute: 30 });

  return (
    <ManagedViewContext
      list="Tours"
      dataSource="Portal Scheduler"
      linkedTo={`Centres/${centreId}`}
      filter={`(tour_booking_date_and_time ge '${startOfWeek.toUTC()}') and (tour_booking_date_and_time lt '${endOfWeek.toUTC()}') and (tour_status ne 'Cancelled')`}
    >
      <SchedulerContext
        mode="readonly"
        startOfWeek={startOfWeek}
        setStartOfWeek={setStartOfWeek}
        timezone={zone}
      >
        <LightboxContext timezone={zone}>
          <Scheduler zone={zone} />
        </LightboxContext>
      </SchedulerContext>
    </ManagedViewContext>
  );
}
