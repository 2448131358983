import { IScheduler, TSchedulerEvent } from '@rapid/dhtmlx-scheduler';
import { Updater } from '@rapid/sdk';

export function onEventChanged<T>(
  events: TSchedulerEvent<T>[],
  updateEvents: Updater<TSchedulerEvent<T>[]>,
) {
  return function _onEventChanged(scheduler: IScheduler, id: string, ev: any) {
    const event = scheduler.getEvent(id);

    updateEvents(d => {
      const ind = d.findIndex(e => e.id === event.id);
      if (ind !== -1) {
        d.splice(ind, 1, ev);
      }
    });
  };
}
