import React from 'react';
import { useListItemContext } from '@rapid/sdk';
import { useRouteMatch } from 'react-router-dom';
import { useCentreAlias } from '../../Store';
import Breadcrumb from '../common/Breadcrumb';
import BreadcrumbItem from '../common/BreadcrumbItem';
import LeadUpdateForm from './LeadUpdateForm';

export default function LeadUpdate() {
  const [{ item }] = useListItemContext();
  const match = useRouteMatch<any>();
  const { leadId } = match.params;

  const centreAlias = useCentreAlias();

  return (
    <>
      <Breadcrumb
        breadcrumbs={[
          { key: 'centre', url: '/centres', displayName: 'Centres' },
          {
            key: 'centre_alias',
            url: `/${match.params.centreId}/${match.params.tab}`,
            displayName: <BreadcrumbItem icon="fa-home">{centreAlias}</BreadcrumbItem>,
          },
          {
            key: 'lead',
            url: `/${match.params.centreId}/${match.params.tab}/lead-${leadId}`,
            displayName: <BreadcrumbItem icon="fa-child">{item?.child ?? 'TBC'}</BreadcrumbItem>,
          },
          {
            key: 'update',
            displayName: <BreadcrumbItem icon="fa-edit">Update Details</BreadcrumbItem>,
          },
        ]}
      />

      <div className="gce-container">
        <LeadUpdateForm />
      </div>
    </>
  );
}
