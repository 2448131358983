import React from 'react';

const SignedOut = () => {
  return (
    <div className="gce-container gce-margin-medium-top">
      <h2>Sign Out</h2>
      <p className="text-success">You have successfully signed out.</p>
    </div>
  );
};

export default SignedOut;
