import { useViewContextCount } from '@rapid/sdk';
import React from 'react';

function Toured() {
  const [count, loading] = useViewContextCount();
  return (
    <>
      <th>Toured</th>
      <td>{loading ? '...' : <span>{count > 0 ? 'YES' : 'NO'}</span>}</td>
    </>
  );
}

export default Toured;
