import { ActionEvent, actionRegistry } from '@rapid/adaptive-framework';
import {
  Updater,
  useCurrentSiteEndpoint,
  useListItemContext,
  useOmnichannelEndpoint,
} from '@rapid/sdk';
import { message, notification } from 'antd';
import checkBlockerCollision from '../Utils/checkBlockerCollision';
import { DateTime } from 'luxon';
import { IScheduler } from '@rapid/dhtmlx-scheduler';
import { ISchedulerContext, TSchedulerTour } from '../Types/SchedulerTypes';
import { IOutlookEvent } from '../Types/ItemTypes';
import { useEffect } from 'react';
import rescheduleTour from '../CRUD/rescheduleTour';
import OffsetTime from '../Utils/offsetTime';

export default function useReschedule(
  scheduler: IScheduler,
  activeEvent: TSchedulerTour | undefined,
  blockingEvents: IOutlookEvent[] | undefined,
  updateEvents: Updater<TSchedulerTour[]>,
  context: ISchedulerContext,
  updateContext: Updater<ISchedulerContext>,
) {
  const ep = useCurrentSiteEndpoint();
  const omniEp = useOmnichannelEndpoint();

  const [listItem] = useListItemContext();

  const onRescheduleTour = (e: ActionEvent) => {
    const event = activeEvent;
    const date = e.args?.date;

    if (!!event) {
      const start_date =
        date instanceof Date ? DateTime.fromJSDate(date) : date;

      const end_date = start_date.plus({
        minutes: 30,
      });

      event.start_date = start_date;
      event.end_date = end_date;
      event.readonly = true;

      if (checkBlockerCollision(blockingEvents ?? [], event)) {
        message.warn('That time slot is blocked');
      } else {
        updateContext(d => {
          d.loading = true;
        });

        rescheduleTour(
          ep,
          event,
          listItem.item?.id as number,
          omniEp as any,
          context.cm?.email,
        )
          .then(res => {
            const newEvent = {
              ...event,
              ...res,
              start_date: OffsetTime(start_date, start_date.zone),
              end_date: OffsetTime(end_date, end_date.zone),
              readonly: true,
            };

            updateEvents(d => {
              const index = d.findIndex(i => i.id === activeEvent?.id) ?? -1;

              if (index !== -1) {
                d.splice(index, 1, newEvent);
              } else {
                d.push(newEvent);
              }
            });

            updateContext(d => {
              d.activeEvent = undefined;
              d.mode = 'readonly';
              d.loading = false;
            });

            scheduler.render();
          })
          .catch((err: any) => {
            console.log(err);

            updateContext(d => {
              d.activeEvent = undefined;
              d.mode = 'readonly';
              d.loading = false;
            });

            scheduler.render();

            notification.warning({
              message: 'Error rescheduling tour',
              description:
                'Reload the page and try again. If the issue persist contact your support personnel',
              duration: 0,
            });
          });
      }
    }
  };

  useEffect(() => {
    actionRegistry.addEventListener('reschedule-tour', onRescheduleTour as any);

    return function cleanup() {
      actionRegistry.removeEventListener(
        'reschedule-tour',
        onRescheduleTour as any,
      );
    };
  }, [activeEvent]);
}
