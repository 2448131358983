import React from 'react';
import { useViewContextCount } from '@rapid/sdk';

interface IViewContextCounterProps {
  label: string;
  title?: string;
}

export default function ViewContextCounter({
  label,
  title,
}: IViewContextCounterProps) {
  const [count] = useViewContextCount();

  return (
    <div
      title={title}
      className="gce-counter gce-card gce-card-default gce-card-body gce-padding-small"
    >
      <div className="gce-text-meta gce-counter-label">
        {label ?? 'Counter'}
      </div>
      <div className="gce-counter-value gce-text-center gce-padding-medium gce-text-large">
        {count ?? 0}
      </div>
    </div>
  );
}
