import React, { useMemo } from 'react';
// import { AdaptiveForm, IFormElement } from '@rapid/adaptive-forms';
import {
  IValidationError,
  KIconTypes,
  useCurrentSite,
  useCurrentSiteEndpoint,
  useImmer,
  useListItemContext,
  useRapidApplication,
} from '@rapid/sdk';
import { notification } from 'antd';
import { useState } from 'react';
import ActionCard from '../ActionCard';
import { Moment } from 'moment';
import { IElement } from '@rapid/data-model';
import {
  AdaptiveRenderer,
  UncontrolledAdaptiveRenderer,
  useAdaptiveData,
  useRapidUpdater,
} from '@rapid/adaptive-framework';
import jsonpath from 'jsonpath';

export type KCardTypes = 'complete' | 'delay' | 'note';

export interface IActionCard {
  name: string;
  icon: KIconTypes;
  label: React.ReactNode;
  buttonClassName?: string;
  form: IElement[];
  buttonLabel: string;
}

const cards: Record<string, IActionCard> = {
  complete: {
    buttonLabel: 'Submit',
    icon: 'fa-check-circle',
    name: 'complete',
    label: 'Complete Task',
    buttonClassName: 'gce-button-secondary gce-green',
    form: [
      {
        $type: 'Input.MultiLineText',
        id: 'body',
        dataPath: '$.body',
        attributes: {
          placeholder: 'Add a note...',
        },
      },
    ],
  },
  delay: {
    buttonLabel: 'Delay task',
    icon: 'fa-calendar-edit',
    name: 'delay',
    label: 'Delay task',
    buttonClassName: 'gce-button-secondary gce-red',
    form: [
      {
        $type: 'Input.DateTime',
        id: 'due_date',
        dataPath: '$.due_date',
        attributes: { label: 'New Due Date' },
      },
      {
        $type: 'Input.MultiLineText',
        id: 'body',
        dataPath: '$.body',
        attributes: {
          placeholder: 'Add a note...',
        },
      },
    ],
  },
};

export default function TaskCards() {
  const [{ item: task, updateItem: updateTask, validateItem, fetchTimeline }] =
    useListItemContext();
  const [app] = useRapidApplication();
  const [site] = useCurrentSite();
  const siteEndpoint = useCurrentSiteEndpoint();
  const [data, updateData] = useImmer<Record<string, any>>({});
  const [activeCard, setActiveCard] = useState<KCardTypes>();

  const onCardClick = (cardName: KCardTypes) => {
    if (cardName === activeCard) {
      setActiveCard(undefined);
      return;
    }

    setActiveCard(cardName);
  };

  const submit = async () => {
    if (!task) {
      return;
    }

    if (activeCard !== 'note') {
      let errors: IValidationError[] | undefined;

      if (activeCard !== 'delay') {
        errors = validateItem?.(true);
        for (const err of errors ?? []) {
          err.error = err.error.replace(/ must be a([\s\S]*)$/, '');
        }
      }

      if (errors?.length) {
        notification.error({
          placement: 'bottomRight',
          message: (
            <>
              <div>
                Please fill out the following fields:
                <ul>
                  {errors.map(error => {
                    const list = site?.Lists.find(
                      i => i.ListName === error.list,
                    );
                    const field = list?.Fields.find(
                      i => i.ColumnName === error.error,
                    );

                    return <li key={error.error}>{field?.Title ?? error}</li>;
                  })}
                </ul>
              </div>
            </>
          ),
          duration: 5,
        });
        return;
      }

      updateTask?.(d => {
        if (activeCard === 'complete') {
          d.status = 'Completed';
        }

        if (data.due_date instanceof Date) {
          d.due_date = data.due_date.toISOString();
        } else if (!!data.due_date) {
          d.due_date = data.due_date;
        }
      });
    }

    if (!!data.body) {
      const linkedItemsToAdd = await siteEndpoint.Lists.Tasks.items[task.id!][
        'inherited-links'
      ].getJson({ type: 'Notes' });

      const type = task.__metadata.type;

      await siteEndpoint.Lists.Notes.items.postJson(
        {},
        {
          title: `Re: Task <a data-item-ref="${type}/${task.id}">${task.title}</a> &mdash; ${app.currentPrincipalDisplayName}`,
          body: data.body,
          type: 'Comment',
          LinkedItemsToAdd: [`${type}/${task.id}`, ...linkedItemsToAdd],
        },
      );
    }

    setActiveCard(undefined);
    updateData(() => ({}));
    fetchTimeline?.(true);
    notification.success({
      message: 'Item saved',
      duration: 5,
    });
  };

  const schema = useMemo(
    function generateSchema() {
      if (activeCard) {
        return {
          $type: 'Layout.Section',
          id: 'root-section',
          $children: cards[activeCard].form,
        } as IElement;
      } else {
        return {
          $type: 'Layout.Section',
          id: 'root',
        } as IElement;
      }
    },
    [activeCard],
  );

  const [update, forceUpdate] = useRapidUpdater();
  const [_data, _updateData, _onDataChange] = useAdaptiveData(
    data,
    schema,
    forceUpdate,
    'edit',
    [schema],
  );

  const onDataChange = (
    element: IElement,
    data: any,
    path?: string,
    key?: string,
  ) => {
    if (!!path) {
      updateData(d => {
        jsonpath.value(d, path, data);
      });
    }

    _onDataChange(element, data, path, key);
  };

  return (
    <>
      <div className="TaskCards gce-grid gce-margin-medium-top gce- fgrid-match">
        {Object.values(cards).map(card => (
          <ActionCard
            {...card}
            active={activeCard === card.name}
            onClick={onCardClick}
            key={card.name}
          />
        ))}
      </div>
      {!!activeCard && (
        <div className="TaskCards gce-component-container gce-padding gce-margin-medium-top">
          <div className="TaskAdaptive">
            <UncontrolledAdaptiveRenderer
              schema={schema}
              onDataChange={onDataChange}
              update={update}
              forceUpdate={forceUpdate}
              mode="edit"
            />
          </div>
          <button
            onClick={submit}
            className="gce-button gce-button-blue gce-margin-top"
          >
            {cards[activeCard].buttonLabel}
          </button>
        </div>
      )}
    </>
  );
}
