import {
  ManagedViewContext,
  useDocumentTitle,
  useListItemContext,
} from '@rapid/sdk';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { startOfToday, startOfTomorrow } from '../common/utils/filters';
import { Store, useStore } from '../Store';
import CentreTab from './CentreTab';
import CentreTabStrip from './CentreTabStrip';
import Breadcrumb from './common/Breadcrumb';
import BreadcrumbItem from './common/BreadcrumbItem';
import SimpleSpinner from './Spinner/SimpleSpinner';
import { FetchMyCentresUsersTasksHOC } from './untils';
import ViewContextCounter from './ViewContextCounter';

export default function CentreProfile() {
  const [, updateStore] = useStore();
  const [{ item: centre }] = useListItemContext();
  const match = useRouteMatch<{
    centreId: string;
    tab?: keyof Store['tabs'];
  }>();
  const centreId = +/^(\d+)/.exec(match.params.centreId)?.[0]!;

  useDocumentTitle(
    () => `${centre?.centre_alias ?? 'Unknown'} - Waitlist`,
    [centre],
  );

  useEffect(
    function centreChangedEffect() {
      updateStore(draft => {
        draft.centreEmail = centre?.centre_email;
        draft.centreTimezone = centre?.iana_timezone;
      });
    },
    [centre],
  );

  if (!centre) {
    return (
      <div className="gce-container gce-margin-small-top">
        <SimpleSpinner />
      </div>
    );
  }

  return (
    <ManagedViewContext
      list="Centre Users"
      filter={`centre_id eq ${centreId}`}
      dataSource="all"
    >
      <Breadcrumb
        breadcrumbs={[
          { key: 'centre', url: '/centres', displayName: 'Centres' },
          {
            key: 'centre_alias',
            displayName: (
              <BreadcrumbItem icon="fa-home">
                {centre.centre_alias}
              </BreadcrumbItem>
            ),
          },
        ]}
      />

      <div className="gce-container content-block gce-padding-top">
        <div className="gce-grid">
          <div className="gce-width-1-4@s">
            <FetchMyCentresUsersTasksHOC
              count={true}
              filter={`due_date lt '${startOfToday()}'`}
              dataSource="Portal Overdue Tasks"
            >
              <ViewContextCounter label="Overdue Tasks" />
            </FetchMyCentresUsersTasksHOC>
          </div>

          <div className="gce-width-1-4@s">
            <FetchMyCentresUsersTasksHOC
              count={true}
              filter={`due_date ge '${startOfToday()}' and due_date lt '${startOfTomorrow()}'`}
              dataSource="Portal Todays Tasks"
            >
              <ViewContextCounter label="Today's Tasks" />
            </FetchMyCentresUsersTasksHOC>
          </div>
          <div className="gce-width-1-4@s">
            <ManagedViewContext
              count
              linkedTo={`Centres/${centreId}`}
              list="Tours"
              dataSource="Portal Tours"
              filter={`tour_booking_date_and_time ge '${DateTime.now().toISODate()}'`}
            >
              <ViewContextCounter label="Tours" />
            </ManagedViewContext>
          </div>
          <div className="gce-width-1-4@s">
            <ManagedViewContext
              count
              linkedTo={`Centres/${centreId}`}
              list="Bonds"
              dataSource="Portal Offers"
            >
              <ViewContextCounter label="Offers Outstanding" />
            </ManagedViewContext>
          </div>
        </div>

        <CentreTabStrip />

        <section id="enrolledContainer" className="gce-component-container">
          {match.params.tab && (
            <CentreTab
              centreId={centreId}
              tab={match.params.tab}
              timezone={centre.iana_timezone}
            />
          )}
          {!match.params.tab && (
            <Redirect to={`${match.params.centreId}/tasks`} />
          )}
        </section>
      </div>
    </ManagedViewContext>
  );
}
