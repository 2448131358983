import { useComposedClassName } from '@rapid/sdk';
import React from 'react';
import { useCTContext } from '../../../Contexts/CTContext/CTContext';
import './CTTabs.scss';

export default function CTTabs() {
  const [isCT, setIsCT] = useCTContext();

  const onTabClick = (type: 'CM' | 'CT') => () => {
    if (type === 'CT') {
      setIsCT(true);
    }

    if (type === 'CM') {
      setIsCT(false);
    }
  };

  const selectionTabsClass = useComposedClassName(
    function* () {
      yield 'SelectionTypeTabs';

      if (isCT) {
        yield 'CT';
      } else {
        yield 'CM';
      }
    },
    [isCT],
  );

  return (
    <div className={selectionTabsClass}>
      <div className="CM TabButton" onClick={onTabClick('CM')}>
        <span className="TabButtonContent">Centre Managers</span>
      </div>
      <div className="CT TabButton" onClick={onTabClick('CT')}>
        <span className="TabButtonContent">CT Team</span>
      </div>
    </div>
  );
}
