import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { ManagedListItemContext, useListItemContext } from '@rapid/sdk';
import { useStore } from '../../Store';

export default function LeadContext({ children }: React.PropsWithChildren<{}>) {
  const match = useRouteMatch<any>();

  const [{ item: centre }] = useListItemContext(); // centre
  const [, updateStore] = useStore();

  useEffect(function centreChangedEffect() {
    updateStore(d => {
      d.centreEmail = centre?.centre_email;
    });
  }, [centre?.centre_email]);

  return (
    <ManagedListItemContext list="Leads" id={match.params.leadId}>
      {children}
    </ManagedListItemContext>
  );
}
