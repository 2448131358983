import { IScheduler } from '@rapid/dhtmlx-scheduler';

export const onMouseMove =
  (
    columnDay: number | undefined,
    setColumnDay: React.Dispatch<React.SetStateAction<number | undefined>>,
    classRef: React.RefObject<HTMLDivElement>,
  ) =>
  (scheduler: IScheduler, id: string | null, ev: MouseEvent) => {
    let _columnDay = (ev.target as HTMLElement).dataset.columnIndex;

    if (!_columnDay) {
      _columnDay = (ev.target as HTMLElement).parentElement?.dataset
        .columnIndex;
    }

    const cellStep = scheduler.config.time_step;

    const hours = scheduler.config.last_hour - scheduler.config.first_hour;

    const times = (hours * 60) / cellStep;

    const height = scheduler.config.hour_size_px * (cellStep / 60);

    const hourStep = cellStep / 60;

    if (!!_columnDay) {
      console.log(
        ev.offsetY,
        (ev.target as HTMLElement).parentElement?.getBoundingClientRect(),
      );

      classRef.current?.style.setProperty(
        `--cell-${_columnDay}-offset-y`,
        `${ev.offsetY - height / 2}px`,
      );
    }
  };
