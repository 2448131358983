import {
  ManagedViewContext,
  useCurrentSiteEndpoint,
  useRapidApplication,
} from '@rapid/sdk';
import kebabCase from 'lodash/kebabCase';
import React, { useEffect, useRef, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { centreColumns } from './common/constaints';
import CentreContext from './Components/CentreContext';
import CentreList from './Components/CentreList';
import CentreProfile from './Components/CentreProfile';
import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import LeadContext from './Components/Lead/LeadContext';
import LeadProfile from './Components/Lead/LeadProfile';
import LeadUpdate from './Components/Lead/LeadUpdate';
import Nag from './Components/Nag';
import Report from './Components/Report/Report';
import SignedOut from './Components/SignedOut';
import LoadingSpinner from './Components/Spinner/LoadingSpinner';
import TaskContext from './Components/Task/TaskContext';
import TaskProfile from './Components/Task/TaskProfile';
import { useStore } from './Store';

interface ISchedulerAdminUser {
  user_id: number;
  status: 'Active' | 'Inactive';
}

export default function IndexPage() {
  const [app] = useRapidApplication();
  const ep = useCurrentSiteEndpoint();
  const [store, updateStore] = useStore();
  const [showScroll, setShowScroll] = useState(false);
  const scrollRef = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(
    function setIsAdminUserEffect() {
      ep.Lists['Scheduler Admin Users'].All.Items.getJson().then(res => {
        const adminUser = (res.value as ISchedulerAdminUser[]).find(
          u => u.user_id === app.currentPrincipalId,
        );

        if (!!adminUser && adminUser?.status === 'Active') {
          updateStore(d => {
            d.isAdmin = true;
          });
        } else {
          updateStore(d => {
            d.isAdmin = false;
          });
        }
      });
    },
    [app.currentPrincipalId],
  );

  useEffect(function componentDidMountEffect() {
    if (!isNaN(store.principalId)) {
      ep.Lists['Centre Users'].All$.Items.getJson({
        $filter: `user_id eq ${app.currentPrincipalId}`,
        $select: 'id,sys_type_id,user_id,centre_id,centre,user',
      })
        .then(res => {
          if (res.value?.length && !!res.value[0].centre_id) {
            updateStore(draft => {
              draft.myCentre = res.value[0];
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    const scrollWindow = document.querySelector('.gce-site-main');
    scrollWindow?.addEventListener('scroll', checkScrollTop);

    return function componentWillUnmountEffect() {
      const scrollWindow = document.querySelector('.gce-site-main');

      scrollWindow?.removeEventListener('scroll', checkScrollTop);
    };
  }, []);

  const checkScrollTop = () => {
    const scrollWindow = document.querySelector('.gce-site-main');
    if (scrollWindow && scrollWindow.scrollTop > 20) {
      setShowScroll(true);
    } else {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    const scrollWindow = document.querySelector('.gce-site-main');
    scrollWindow?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  if (loading) {
    return <LoadingSpinner type="fullpage" />;
  }

  return (
    <div className="gce-site-main gce-position-relative" ref={scrollRef}>
      <div className="gce-main-body">
        <a
          id="scrollTopButton"
          className="gce-bookmark"
          style={{ display: showScroll ? 'block' : 'none' }}
        >
          <div onClick={scrollTop}>
            <i className="fad fa-fw fa-arrow-to-top fa-2x"></i>
          </div>
        </a>

        <Header />

        <Switch>
          <Route path="/report">
            <Report />
          </Route>

          <Route path="/centres">
            <ManagedViewContext
              list="Centres"
              dataSource="All$"
              columns={centreColumns}
            >
              <CentreList />
            </ManagedViewContext>
          </Route>

          <Route exact path="/SignedOut">
            <SignedOut />
          </Route>

          <Route path="/:centreId">
            <CentreContext>
              <Switch>
                <Route path="/:centreId/:tab/task-:taskId">
                  <TaskContext>
                    <TaskProfile />
                  </TaskContext>
                </Route>

                <Route path="/:centreId/:tab/lead-:leadId">
                  <LeadContext>
                    <Switch>
                      <Route path="/:centreId/:tab/lead-:leadId/edit">
                        <LeadUpdate />
                      </Route>

                      <Route path="/:centreId/:tab/lead-:leadId">
                        <LeadProfile />
                      </Route>
                    </Switch>
                  </LeadContext>
                </Route>

                <Route path="/:centreId/:tab?">
                  <CentreProfile />
                </Route>
              </Switch>
            </CentreContext>
          </Route>

          {!loading && store.myCentre && (
            <Redirect
              exact
              from="/"
              to={`/${store.myCentre.centre_id}-${kebabCase(
                store.myCentre.centre,
              )}`}
            />
          )}

          <Route>{!loading && !store.myCentre && <Nag />}</Route>
        </Switch>

        <Footer />
      </div>
    </div>
  );
}
