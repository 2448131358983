import { AnyListItem } from '@rapid/data-model';
import { ISiteAPIEndpoint } from '@rapid/sdk/lib/api/rapid-platform.api';
import { IChild, IContact, IFamily, ILead } from '../Types/ItemTypes';

export interface IOnCreateLeadReturn {
  lead: AnyListItem<ILead>;
  child?: AnyListItem<IChild>;
  family?: AnyListItem<IFamily>;
  contact?: AnyListItem<IContact>;
}

export default async function createLead(
  ep: ISiteAPIEndpoint,
  data: any,
  centre_id: number,
): Promise<IOnCreateLeadReturn> {
  let family: AnyListItem<IFamily> | undefined;

  const isEditing = !!data.contact?.id;

  let familyId = data.contact?.family_id;

  if (!isEditing && !!data.contact.first_name && !!data.contact.last_name) {
    [family] = await ep.Lists.Family.items.postJson(
      {},
      {
        family_name: `${data.contact.first_name} ${data.contact.last_name}`,
      },
    );

    familyId = family?.id;
  }

  if (!!familyId && isEditing) {
    family = await ep.Lists.Family.All$.Items[familyId].getJson();
  }

  let child: AnyListItem<IChild> | undefined;

  if (!!data.child) {
    [child] = await ep.Lists.Child.items.postJson(
      {},
      { ...(data?.child ?? {}), family_id: familyId },
    );
  }

  let contact: AnyListItem<IContact>;

  if (isEditing) {
    contact = await ep.Lists.Contact.items[data.contact.id].putJson(
      {},
      data.contact,
    );
  } else {
    [contact] = await ep.Lists.Contact.items.postJson(
      {},
      { ...(data?.contact ?? {}), family_id: familyId },
    );
  }

  const [lead]: AnyListItem<ILead>[] = await ep.Lists.Lead.items.postJson(
    {},
    {
      ...(data.lead ?? {}),
      child_id: child?.id,
      family_id: family?.id,
      primary_contact_id: contact.id,
      ScopedLinkedItemsToAdd: [
        {
          centre_id,
          column_name: 'centres',
          entity_type_id: 21,
        },
      ],
    },
  );

  const gotLead = await ep.Lists.Leads.Items[lead?.id].getJson();

  return {
    lead: gotLead,
    child,
    family,
    contact,
  };
}
