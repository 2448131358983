import { Input } from 'antd';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import DateTimePicker, {
  IValidDateRange,
} from '../../DateTimePicker/DateTimePicker';

interface IBlockingFormProps {
  loading?: boolean;
  onSubjectChange(e: React.ChangeEvent<HTMLInputElement>): void;
  onDateChange(key: 'start' | 'end'): (date: DateTime) => void;
  subject?: string;
  initialStart?: DateTime;
  initialEnd?: DateTime;
}

export default function BlockingForm({
  loading,
  onDateChange,
  onSubjectChange,
  initialEnd,
  initialStart,
  subject,
}: IBlockingFormProps) {
  const [startDt, setStartDt] = useState<DateTime | undefined>(initialStart);
  const [endDt, setEndDt] = useState<DateTime | undefined>(initialEnd);

  useEffect(
    function setStartAndEndDtEffect() {
      if (!!initialStart) setStartDt(initialStart);
      if (!!initialEnd) setEndDt(initialEnd);
    },
    [initialStart, initialEnd],
  );

  const endValidDateRange = useMemo(
    function getEndValidTimeRange() {
      if (!!startDt && startDt.isValid) {
        return {
          start: startDt,
        } as IValidDateRange;
      }
    },
    [startDt],
  );

  const _onDateChange = (key: 'start' | 'end') => (date: DateTime) => {
    if (key === 'start') setStartDt(date);
    if (key === 'end') setEndDt(date);

    onDateChange(key)(date);
  };

  return (
    <div className="FormContainer">
      {!!loading && (
        <div className="CreatingBlocking">
          <i className="fal fa-fw fa-spinner-third fa-spin" />
          &ensp;Creating blocker...
        </div>
      )}

      <div className="Subject Label field-wrapper">
        <label htmlFor="subject" className="field-label">
          Subject
        </label>
        <Input id="subject" onChange={onSubjectChange} value={subject} />
      </div>

      <div className="DateTimeWrapper">
        <div className="Start Label field-wrapper">
          <label htmlFor="start" className="field-label">
            Start
          </label>
          <DateTimePicker onChange={_onDateChange('start')} value={startDt} />
        </div>

        <div className="End Label field-wrapper">
          <label htmlFor="end" className="field-label">
            End
          </label>
          <DateTimePicker
            onChange={_onDateChange('end')}
            value={endDt}
            validDateRange={endValidDateRange}
          />
        </div>
      </div>
    </div>
  );
}
