import { Spinner } from '@rapid/sdk/lib/ui';
import * as React from 'react';
import './LoadingSpinner.scss';

export interface ISpinnerProps {
  type: 'fullpage' | 'component';
}

function LoadingSpinner(props: ISpinnerProps) {
  return (
    <div className={`LoadingSpinner LoadingSpinner-${props.type}`}>
      <div className="text-container">
        <span>Loading...</span>
        <Spinner />
      </div>
    </div>
  );
}

export default LoadingSpinner;
