import React from 'react';
import { AnyListItem } from '@rapid/data-model';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import DetailItem from '../DetailItem';
import Attachments from '../Attachments';
import { AttachmentsContext } from '@rapid/sdk';

interface ITaskDetailProps {
  task: AnyListItem;
  onSave?(): void;
}

export default function TaskDetail({ task, onSave }: ITaskDetailProps) {
  const taskStartDate = useMemo(() => {
    const lux = task && DateTime.fromISO(task.start_date);
    return {
      relative: lux?.toRelative(),
      format: lux?.toFormat('LLL dd yyyy'),
    };
  }, [task]);

  const taskDueDate = useMemo(() => {
    const lux = task && DateTime.fromISO(task.due_date);

    return {
      relative: lux?.toRelative(),
      format: lux?.toFormat('LLL dd yyyy'),
    };
  }, [task]);

  const description = useMemo(() => {
    if (task?.description) {
      const ta = document.createElement('textarea');
      ta.innerHTML = decodeURIComponent(task.description);
      return ta.value;
    }
  }, [task?.description]);

  function onTaskSaveClicked() {
    onSave?.();
  }

  return (
    <div
      id="waitlistItemDetailsContainer"
      className="gce-component-container"
      style={{ position: 'relative' }}
    >
      <div className="gce-grid">
        <div className="gce-width-1-2@s gce-width-1-3@m">
          <table className="gce-table-detail">
            <tbody>
              <DetailItem
                title="Assigned to"
                iconName="fa-user-circle"
                value={task.assigned_to}
                className="AssignedTo"
              />
              <DetailItem
                title="Start date"
                iconName="fa-calendar-star"
                value={
                  <div className="Date">
                    <span className="FormatDate">{taskStartDate.format}</span>{' '}
                    <span className="RelativeDate">
                      ({taskStartDate.relative})
                    </span>
                  </div>
                }
                className="StartDate"
              />
              <DetailItem
                title="Due date"
                iconName="fa-calendar-check"
                value={
                  <div className="Date">
                    <span className="FormatDate">{taskDueDate.format}</span>{' '}
                    <span className="RelativeDate">
                      ({taskDueDate.relative})
                    </span>
                  </div>
                }
                className="DueDate"
              />
              <DetailItem
                title="Priority"
                iconName="fa-exclamation-triangle"
                value={task.priority}
                className="Priority"
              />
              <DetailItem
                title="Status"
                iconName="fa-info-circle"
                value={task.status}
                className="Status"
              />
            </tbody>
          </table>
        </div>
        <div className="gce-position-relative gce-width-1-2@s gce-width-2-3@m">
          <div className="gce-position-absolute gce-position-top-right">
            {/* <button
              className="gce-button gce-button-secondary gce-green"
              onClick={onTaskSaveClicked}
            >
              <i className="fa fa-fw fa-save"></i>
              Save
            </button> */}
          </div>
          <table className="gce-table-detail">
            <tbody>
              <tr className="gce-table-description-row">
                <th className="gce-table-detail-icon">
                  <i className={`gce-table-icon fal fa-file-alt`} />
                </th>
                <th className="gce-table-detail-title">Description</th>
                <td className="gce-table-detail-value"></td>
              </tr>
            </tbody>
          </table>
          {/*

            TODO

            We need to add sanitizeHtml into the SDK so we can
            use the same rules to sanitize things outside of rapid
            as we use inside of rapid. 

          */}
          {!!description && (
            <div
              className="gce-override-dark-muted"
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
