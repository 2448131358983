import { useViewContext } from '@rapid/sdk';
import React from 'react';
import { useStore } from '../../../../Store';
import Toolbar from '../../../Toolbar/Toolbar';
import ToolbarButton from '../../../Toolbar/ToolbarButton';
import { useLightbox } from '../../Contexts/LightboxContext/LightboxContext';
import {
  useBlockingContext,
  useSchedulerContext,
} from '../../Contexts/SchedulerContext/SchedulerContext';
import useKeyPress from '../../Hooks/useKeyPress';
import './SchedulerToolbar.scss';

export default function SchedulerToolbar() {
  const { context, updateContext } = useSchedulerContext();
  const [store] = useStore();
  const { openLightbox } = useLightbox();
  const [, , fetchAvailability] = useBlockingContext();
  const [viewContext] = useViewContext();

  const handleEscapePress = () => {
    if (context.mode === 'reschedule') {
      onCancelReschedule();
    }
  };

  useKeyPress(['Escape'], handleEscapePress);

  const onCreateOneOff = () => {
    openLightbox('create-one-off');
  };

  const onCancelReschedule = () => {
    updateContext(d => {
      d.mode = 'readonly';
    });
  };

  const onUpdateClick = () => {
    fetchAvailability();
    viewContext.fetchItems();
  };

  const onBlockTimeslot = () => {
    openLightbox('create-blocking');
  };

  return (
    <Toolbar className="SchedulerToolbar">
      {context.mode !== 'reschedule' && (
        <>
          <ToolbarButton className="OneOff" onClick={onCreateOneOff}>
            <i className="fas fa-fw fa-calendar-circle-plus ToolbarIcon" />
            &ensp;Create one off booking
          </ToolbarButton>
          {store.isAdmin && (
            <ToolbarButton className="BlockTime" onClick={onBlockTimeslot}>
              <i className="fas fa-fw fa-calendar-xmark ToolbarIcon" />
              &ensp;Block timeslot
            </ToolbarButton>
          )}
          <ToolbarButton className="Update" onClick={onUpdateClick}>
            <i className="fas fa-fw fa-rotate ToolbarIcon" />
            &ensp;Update
          </ToolbarButton>
        </>
      )}
      {context.mode === 'reschedule' && (
        <ToolbarButton className="CancelResch" onClick={onCancelReschedule}>
          <i className="fas fa-fw fa-times ToolbarIcon" />
          &ensp;Cancel Reschedule
        </ToolbarButton>
      )}

      <ToolbarButton disabled>
        <i className="fas fa-fw fa-clock ToolbarIcon" />
        <small className="text-muted">{store.centreTimezone}</small>
      </ToolbarButton>
    </Toolbar>
  );
}

/* 
  Create walk in tour

  Guardian have asked for create walk in to be removed
  Below is the code to bring it back. So we can turn it
  back on if they change their minds

  const onCreateWalkIn = () => {
    openLightbox('select-contact-or-lead');
  };
  
  <ToolbarButton className="WalkIn" onClick={onCreateWalkIn}>
    <i className="fas fa-fw fa-user-plus ToolbarIcon" />
    &ensp;Record walk in tour
  </ToolbarButton>
*/
