import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Store, useStore } from '../Store';

export default function CentreTabStrip() {
  const [store] = useStore();
  const match =
    useRouteMatch<{ centreId: string; tab?: keyof Store['tabs'] }>();
  const history = useHistory();

  const handleTabClick = (tab: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    history.push(`/${match.params.centreId}/${tab}`);
  };

  return (
    <section className="gce-flex gce-margin-medium-top tabstrip">
      {Object.entries(store.tabs).map(([id, tab]) => (
        <a
          key={id}
          href={`/${match.params.centreId}/${id}`}
          className={`gce-flex-1 tabstrip__item ${
            id === match.params.tab ? 'tabstrip__item--active' : ''
          }`}
          id={id}
          style={{ maxWidth: tab.maxWidth }}
          onClick={handleTabClick(id)}
        >
          {tab.loading && (
            <div className="tabstrip__item__icon">
              <i className="fal fa-spin fa-spinner-third"></i>
            </div>
          )}
          <i id={id} className={`fal fa-fw ${tab.iconName}`}></i>
          {tab.label}
        </a>
      ))}
    </section>
  );
}
