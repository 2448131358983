import { useImmer } from '@rapid/sdk';
import { Button } from 'antd';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { useLightbox } from '../../../Contexts/LightboxContext/LightboxContext';
import { useBlockingContext } from '../../../Contexts/SchedulerContext/SchedulerContext';
import { TLightboxMode, TLightboxOnClose } from '../../../Types/SchedulerTypes';
import Message from '../Message/Message';
import BlockingForm from './BlockingForm';
import './Blocking.scss';
import OffsetTime from '../../../Utils/offsetTime';

interface IEditBlockingProps {
  onCloseLightbox(onClose: TLightboxOnClose): void;
  setLightboxMode(mode: TLightboxMode): void;
  timezone?: string;
}

export default function EditBlocking({
  onCloseLightbox,
  setLightboxMode,
  timezone
}: IEditBlockingProps) {
  const { blockerId, catchLightboxError } = useLightbox();

  const [, blockingEvents, , , updateBlocker] = useBlockingContext();

  const [updating, setUpdating] = useState(false);

  const [blocking, updateBlocking] = useImmer({
    subject: '',
    start: {
      dateTime: undefined as string | undefined,
      timeZone: 'UTC',
    },
    end: {
      dateTime: undefined as string | undefined,
      timeZone: 'UTC',
    },
  });

  const [initialStart, setInitialStart] = useState<DateTime>();
  const [initialEnd, setInitialEnd] = useState<DateTime>();

  useEffect(
    function setBlockingOnMount() {
      const blocker = blockingEvents?.find(o => o.id === blockerId);

      let startDt = '';

      if (!!blocker?.start?.dateTime) {
        const initStart = OffsetTime(DateTime.fromISO(blocker?.start.dateTime, {
          zone: blocker?.start.timeZone,
        }), timezone).toLocal();

        setInitialStart(initStart);

        startDt = initStart.toISO();
      }

      let endDt = '';

      if (!!blocker?.start?.dateTime) {
        const initEnd = OffsetTime(DateTime.fromISO(blocker?.end.dateTime, {
          zone: blocker?.end.timeZone,
        }), timezone).toLocal();

        setInitialEnd(initEnd);

        endDt = initEnd.toISO();
      }

      updateBlocking(d => {
        d.subject = blocker?.subject ?? d.subject;
        d.start.dateTime = startDt;
        d.end.dateTime = endDt;
      });
    },
    [blockingEvents],
  );

  const onUpdateBlocker = () => {
    if (!!blockerId) {
      setUpdating(true);
      updateBlocker(blockerId, blocking)
        .then(() => {
          setUpdating(false);
          onCloseLightbox('close');
        })
        .catch(catchLightboxError);
    }
  };

  const onRemoveBlocker = () => {
    setLightboxMode('remove-blocking');
  };

  const onDateChange = (key: 'start' | 'end') => (date: DateTime) => {
    if (key === 'start') {
      updateBlocking(d => {
        d.start.dateTime = date.toUTC().toISO();
      });
    }

    if (key === 'end') {
      updateBlocking(d => {
        d.end.dateTime = date.toUTC().toISO();
      });
    }
  };

  const onSubjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateBlocking(d => {
      d.subject = e.target.value;
    });
  };

  return (
    <div className="EditBlocking Blocking">
      <Message
        title={`Edit ${blocking?.subject}`}
        subTitle="You can edit the blocker to change it's time and date or remove it all together"
        icon="fad fa-fw fa-calendar-xmark"
      />

      <BlockingForm
        loading={updating}
        onDateChange={onDateChange}
        onSubjectChange={onSubjectChange}
        subject={blocking.subject}
        initialStart={initialStart}
        initialEnd={initialEnd}
      />

      <div className="BlockingActions">
        <Button
          className="BlockingButton Remove"
          onClick={onRemoveBlocker}
          type="text"
          danger
        >
          Remove timeslot
        </Button>

        <Button
          className="BlockingButton Update"
          onClick={onUpdateBlocker}
          type="primary"
        >
          Update timeslot
        </Button>
      </div>
    </div>
  );
}
