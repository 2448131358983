import { DateTime, IANAZone, Interval } from 'luxon';
import { TSchedulerTour } from '../Types/SchedulerTypes';
import OffsetTime from './offsetTime';

export default function checkEventCollision(
  events: TSchedulerTour[],
  event: TSchedulerTour | DateTime,
): boolean {
  let startDate: DateTime;
  let endDate: DateTime;

  if (event instanceof DateTime) {
    startDate = event;
    endDate = event.plus({ minute: 30 });
  } else {
    startDate = DateTime.fromJSDate(event.start_date as unknown as Date);
    endDate = DateTime.fromJSDate(event.end_date as unknown as Date);
  }

  const dateInt = Interval.fromDateTimes(startDate, endDate);
  const zone = event['zone'] as unknown as IANAZone;

  for (const _event of events) {
    const startDt = OffsetTime(
      DateTime.fromJSDate(_event.start_date as unknown as Date),
      zone,
      'Local to Zone',
    ).setZone(zone);

    const endDt = OffsetTime(
      DateTime.fromJSDate(_event.end_date as unknown as Date),
      zone,
      'Local to Zone',
    ).setZone(zone);

    const eventInt = Interval.fromDateTimes(startDt, endDt);

    if (eventInt.overlaps(dateInt)) {
      return true;
    }
  }

  return false;
}
