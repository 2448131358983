import React from 'react';
import { ITimelineEvent, TimelineEventType } from '@rapid/data-model';
import { useComposedClassName } from '@rapid/sdk';
import { DateTime } from 'luxon';
import { SanitizedDiv } from '@rapid/sdk';
import { useMemo } from 'react';

interface ITimelineItemProps {
  item: ITimelineEvent;
}

export default function TimelineItem({ item }: ITimelineItemProps) {
  const lux = useMemo(
    () => DateTime.fromISO(item.date ?? '').toFormat('EEEE, dd MMMM yyyy, T'),
    [item.date],
  );

  return (
    <div className="TimelineItem">
      <div className="Note">
        <div className="Title">
          <SanitizedDiv html={item.title} />
        </div>
        <div className="Info">
          <SanitizedDiv html={item.body ?? ''} />
        </div>
        <div className="Date">{lux}</div>
        {/* ) : (
          <div className="UnknownDate">unknown date</div>
        )} */}
      </div>
    </div>
  );
}
