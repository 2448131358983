import React from 'react';

export const days = [
  { label: 'mon', value: 'monday' },
  { label: 'tue', value: 'tuesday' },
  { label: 'wed', value: 'wednesday' },
  { label: 'thu', value: 'thursday' },
  { label: 'fri', value: 'friday' }
];

interface IDayPickerProps {
  selectedDays?: Record<string, boolean>;
  onDayToggle?(day: string): void;
}

export default function DayPicker({
  selectedDays,
  onDayToggle
}: IDayPickerProps) {

  function onChangeInternal(ev: React.ChangeEvent<HTMLInputElement>) {
    const value = ev.target.value;
    onDayToggle?.(value);
  }

  return (
    <div>
      {days.map(item => (
        <label key={item.label} className="gce-check-day">
          {item.label}
          <input
            name={item.value}
            value={item.value}
            type="checkbox"
            checked={selectedDays?.[item.value]}
            onChange={onChangeInternal}
          />
          <span className="checkmark"></span>
        </label>
      ))}
    </div>
  );
}
