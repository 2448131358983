import { AnyListItem } from '@rapid/data-model';
import { useCurrentSiteEndpoint } from '@rapid/sdk';
import get from 'lodash/get';
import React, { useRef, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useTabContext } from '../Store';
import { getDate } from './helper';
import LeadCreateForm from './Lead/LeadCreateForm';
import { getWeekDays } from './untils';

const leadColumns = [
  { path: 'priority', width: '100px', icon: null },
  { path: 'contact_mobile', width: '140px', icon: 'fa-phone' },
  {
    path: 'child_dob',
    width: '100px',
    icon: 'fa-calendar-day',
    content: item => (
      <span>{item.start_date ? getDate(item.child_dob) : ''}</span>
    )
  },
  {
    path: 'start_date',
    width: '120px',
    icon: 'fa-calendar-star',
    content: item => (
      <span>{item.start_date ? getDate(item.start_date) : ''}</span>
    )
  },
  {
    path: 'days',
    width: '100px',
    icon: 'fa-calendar-week',
    content: item => <span>{getWeekDays(item)}</span>
  },
  {
    path: 'waitlisted_date',
    width: '120px',
    icon: 'fa-clipboard-list-check'
  },
  { path: 'enquiry_stage', width: '100px', icon: 'fa-project-diagram' }
];

export default function AddLead() {
  const ep = useCurrentSiteEndpoint();
  const match = useRouteMatch<any>();
  const [tab, updateTab] = useTabContext('createLead');
  const [data, setData] = useState({
    firstPage: true,
    isForm: false,
    nextSkip: '',
    previousSkip: ''
  });
  const [state, setState] = useState({
    isContact: false,
    isLead: false,
    isForm: false
  });
  const searchBoxRef = useRef<HTMLInputElement>();

  const [searchText, setSearchText] = useState(tab.searchKey);
  const [waitVisible, setWaitVisible] = useState<any | null>(false);

  const renderCell = (item, column) => {
    if (column.content) return column.content(item);
    return get(item, column.path);
  };

  const handleSearchContacts = async ev => {
    ev.preventDefault();

    if (searchText) {
      setWaitVisible(true);
      setState({ ...state, isContact: false, isLead: false, isForm: false });
      setWaitVisible(true);

      updateTab(draft => {
        draft.selectedIndex = undefined;
        draft.selectedLeads = [];
      });

      const res = await ep.Lists.Contact['Contacts - All'].Items.getJson({
        term: searchText
      });
      updateTab(draft => {
        // draft.searchKey = searchText;
        draft.data = res.value;
      });
    } else {
      setData({ ...data });
    }

    setWaitVisible(false);
  };

  function onContactClicked(
    ev: React.MouseEvent<HTMLAnchorElement>,
    contact: AnyListItem,
    index: number
  ) {
    ev.preventDefault();

    setState({ ...state, isContact: false, isLead: true, isForm: false });

    let filter = `primary_contact_id eq '${contact.id}'`;

    if (contact.family_id !== null) {
      filter += ` or family_id eq '${contact.family_id}'`;
    }

    updateTab(draft => {
      draft.selectedIndex = index;
    });

    document
      .getElementById('leadSearchResults')
      ?.scrollIntoView({ behavior: 'smooth' });

    ep.Lists.Leads.All$.Items.getJson({ $filter: filter }).then(res => {
      updateTab(draft => {
        draft.selectedLeads = res.value;
      });
    });
  }

  function onGoToTopClicked(e: React.MouseEvent) {
    e.preventDefault();
    document
      .getElementById('newLeadContainer')
      ?.scrollIntoView({ behavior: 'smooth' });

    searchBoxRef.current?.focus();
  }

  const createContact = ev => {
    ev.preventDefault();
    setState({ ...state, isContact: true, isLead: false, isForm: true });
  };

  function onSearchValueChanged(ev: React.ChangeEvent<HTMLInputElement>) {
    const value = ev.target.value;
    setSearchText(value);
  }

  return (
    <section id="newLeadContainer" className="gce-component-container">
      <form id="contactSearch" onSubmit={handleSearchContacts}>
        <p>
          To help prevent duplication, please search for exisitng contacts using
          Name, Email, Phone Number and/or Address
        </p>

        <div className="gce-grid">
          <div className="gce-width-1-1@s gce-width-2-1@m">
            <input
              ref={searchBoxRef as any}
              className="gce-input"
              placeholder="Search for a contact, e.g. name, phone number or e-mail address"
              name="search"
              id="search"
              value={searchText}
              onChange={onSearchValueChanged}
            />
          </div>
        </div>

        <div className="gce-grid">
          <div className="gce-width-1-3"></div>
          <div className="gce-width-1-3 gce-text-center">
            <button
              id="searchContacts"
              className="gce-button gce-button-darkgreen"
            >
              <i className="fal fa-fw fa-search"></i> Search Contacts
            </button>
          </div>
          <div className="gce-width-1-3"></div>
        </div>
      </form>

      <div id="contactSearchResults">
        {tab.data?.length! > 0 && (
          <div className="rapid-list-items">
            <div className="gce-margin-medium-top">
              <p>
                Records were found matching the query. Either select one of the
                existing contacts or click 'New Contact'.
              </p>
            </div>
            <div
              className="rapid-list-group"
              style={{ borderTop: '1px solid #efefef' }}
            >
              {tab.data?.map((item, i) => (
                <a
                  key={item.id}
                  className={`rapid-list-item ${
                    i === tab.selectedIndex ? 'selected' : ''
                  } `}
                  onClick={ev => onContactClicked(ev, item, i)}
                >
                  <div className="gce-flex gce-flex-middle">
                    <div className="gce-flex-none" style={{ padding: '10px' }}>
                      <span
                        className="fa-stack"
                        style={{ verticalAlign: 'top' }}
                      >
                        <i className="fal fa-circle fa-stack-2x"></i>
                        <i className="fal fa-user fa-stack-1x"></i>
                      </span>
                    </div>
                    <div className="gce-flex gce-flex-auto rapid-card-content gce-flex-middle">
                      <div className="gce-flex gce-flex-column gce-flex-auto">
                        <div style={{ margin: 0, fontWeight: 'bold' }}>
                          {item.full_name}
                        </div>
                        <div style={{ margin: 0, fontSize: '0.8rem' }}>
                          {item.family}
                        </div>
                      </div>
                      <div
                        className="gce-flex-nowrap"
                        style={{ width: '25%', fontSize: '0.8rem' }}
                        title="Email"
                      >
                        <i className="fal fa-fw fa-envelope"></i>
                        {item.email}
                      </div>
                      <div
                        className="gce-flex-nowrap"
                        style={{ width: '140px', fontSize: '0.8rem' }}
                        title="Phone"
                      >
                        <i className="fal fa-fw fa-phone"></i>
                        {item.mobile}
                      </div>
                    </div>
                  </div>
                </a>
              ))}
            </div>
            <div className="gce-margin-medium-top"></div>
            <div className="gce-grid">
              <div className="gce-width-1-3"></div>
              <div className="gce-width-1-3 gce-text-center">
                <button
                  className="gce-button gce-button-darkgreen"
                  onClick={createContact}
                >
                  <i className="fal fa-fw fa-plus-circle"></i> New Lead
                </button>
              </div>
            </div>
          </div>
        )}

        {searchText &&
          !waitVisible &&
          Array.isArray(tab.data) &&
          !tab.data.length && (
            <>
              <div className="placeholder">
                <div>
                  <i className="fad fa-fw fa-4x fa-search"></i>
                </div>
                {data['mobile'] || data['email'] ? (
                  <div>No results could be found.</div>
                ) : (
                  <>
                    <div>
                      oops didn't find anything try <code>search contacts</code>{' '}
                      again.
                    </div>
                    <p>Or</p>
                    <div className="gce-width-1-3 gce-text-center">
                      <button
                        className="gce-button gce-button-darkgreen"
                        onClick={createContact}
                      >
                        <i className="fal fa-fw fa-plus-circle"></i> New Lead
                      </button>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
      </div>

      <div id="leadSearchResults">
        {tab.selectedLeads?.length! > 0 && (
          <React.Fragment>
            <div className="gce-margin-medium-top">
              <p>Existing leads were found for this Search.</p>
            </div>

            <div className="rapid-list-items">
              <div
                className="rapid-list-group"
                style={{ borderTop: '1px solid #efefef' }}
              >
                {tab.selectedLeads!.map(elm => (
                  <Link
                    className="rapid-list-item"
                    to={`${match.url}/lead-${elm.id}`}
                  >
                    <div className="gce-flex gce-flex-middle">
                      <div
                        className="gce-flex-none"
                        style={{ padding: '10px' }}
                      >
                        <span
                          className="fa-stack"
                          style={{ verticalAlign: 'top' }}
                        >
                          <i className="fal fa-circle fa-stack-2x"></i>
                          <i className="fal fa-user fa-stack-1x"></i>
                        </span>
                      </div>
                      <div className="gce-flex gce-flex-auto rapid-card-content gce-flex-middle">
                        <div className="gce-flex gce-flex-column gce-flex-auto">
                          <div style={{ margin: 0, fontWeight: 400 }}>
                            {elm.family}
                          </div>
                          <div style={{ margin: 0, fontSize: '0.8rem' }}>
                            {elm.child}
                          </div>
                        </div>
                        {leadColumns.map(column => (
                          <div
                            className="gce-flex-nowrap"
                            style={{ width: '100px', fontSize: '0.8rem' }}
                            title="Priority"
                          >
                            {column.icon && (
                              <i className={`fal fa-fw ${column.icon}`}></i>
                            )}
                            {renderCell(elm, column)}
                          </div>
                        ))}

                        <div
                          className="gce-text-right gce-flex-nowrap"
                          style={{ width: '20px', paddingRight: '10px' }}
                        >
                          {elm.tour_required === 'Yes' ? (
                            <i
                              className="fal fa-fw fa-map-signs"
                              title="Tour Required"
                            />
                          ) : elm.tour_required === 'Complete' ? (
                            <i
                              className="fal fa-fw fa-check"
                              title="Tour Complete"
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </React.Fragment>
        )}

        {searchText && tab.selectedIndex! >= 0 && !tab.selectedLeads?.length && (
          <div className="placeholder">
            <div>
              <i className="fad fa-fw fa-4x fa-search"></i>
            </div>
            <div>No leads could be found for this Contact.</div>
          </div>
        )}
      </div>

      {!!tab.selectedLeads?.length && (
        <div>
          <a href="javascript: void 0" onClick={onGoToTopClicked}>
            Search again
          </a>
        </div>
      )}

      {state.isForm && (
        <LeadCreateForm
        // setContact={setSearchContacts}
        // leadData={contactLead ? contactLead[0] : null}
        />
      )}

      {waitVisible ? (
        <div className={`gce-mask isVisible`}>
          <span className="fa-stack fa-3x">
            <i className="fad fa-spinner-third fa-stack-2x fa-spin"></i>
            <i className="fal fa-question fa-stack-1x"></i>
          </span>
          <div className="gce-text-center" style={{ fontSize: '1.5rem' }}>
            {'Please Wait...'}
          </div>
        </div>
      ) : null}
    </section>
  );
}
