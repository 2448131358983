export const centreColumns = ['id', 'state', 'centre_alias', 'phone_number'];

export const genderOptions = [
  { Text: 'Male', value: 'Male' },
  { Text: 'Female', value: 'Female' },
];

export const yesNoOptions = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];
