import useComposedClassName from '@rapid-platform/use-composed-class-name';
import React from 'react';
import './Message.scss';

interface IMessageProps {
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  icon?: string;
  className?: string;
}

export default function Message({
  title,
  subTitle,
  icon,
  className,
}: IMessageProps) {
  const messageClass = useComposedClassName(
    function* () {
      yield 'Message';
      if (!!className) {
        yield className;
      }
    },
    [className],
  );

  return (
    <div className={messageClass}>
      {icon && <i className={icon} />}
      <div className="Title">{title}</div>
      {subTitle && <div className="SubTitle">{subTitle}</div>}
    </div>
  );
}
