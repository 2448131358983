import { useCurrentSiteEndpoint, useSingleListItemTimeline } from '@rapid/sdk';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

interface IAddNoteFormProps {
  onSubmit?(): void;
}

export default function AddNoteForm({ onSubmit }: IAddNoteFormProps) {
  const [notes, setNotes] = useState('');
  const [, fetchTimeline] = useSingleListItemTimeline();
  const ep = useCurrentSiteEndpoint();

  const { leadId, bondId, tourId } = useParams<any>();

  const noteSubmit = async ev => {
    ev.preventDefault();

    if (notes.length > 0) {
      const linkedItemsToAdd = [] as any[];
      const inheritedLinksToadd: string[] = await ep.Lists.Leads.Items[leadId][
        'inherited-links'
      ].getJson({
        type: 'Notes',
      });

      if (inheritedLinksToadd?.length) {
        linkedItemsToAdd.push(...inheritedLinksToadd);
      }

      linkedItemsToAdd.push(`lead/${leadId}`);
      if (tourId) linkedItemsToAdd.push(`tour/${tourId}`);
      if (bondId) linkedItemsToAdd.push(`bond/${bondId}`);

      const noteData = {
        LinkedItemsToAdd: linkedItemsToAdd,
        body: notes,
      };

      await ep.Lists.Notes.Items.postJson(undefined, noteData);
      fetchTimeline?.(true);
    }

    onSubmit?.();
  };

  return (
    <React.Fragment>
      <form
        id="noteForm"
        className="gce-component-container gce-margin-medium-top"
        onSubmit={noteSubmit}
        style={{ position: 'relative' }}
      >
        <h2 className="gce-text-center">Add note</h2>

        <div className="gce-grid gce-margin">
          <div className="gce-width-1-1">
            <textarea
              id="body"
              name="body"
              className="gce-textarea"
              rows={4}
              onChange={ev => setNotes(ev.target.value)}
            ></textarea>
          </div>
          <div className="gce-width-1-1 gce-margin-top gce-text-center">
            <button
              className="gce-button gce-button-secondary gce-blue"
              type="submit"
            >
              Add note
            </button>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
}
