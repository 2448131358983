import {
  IElement,
  UncontrolledAdaptiveRenderer,
  useAdaptiveData,
  useRapidUpdater,
} from '@rapid/adaptive-framework';
import {
  Updater,
  useComposedClassName,
  useCurrentSiteEndpoint,
  useImmer,
} from '@rapid/sdk';
import { Button, notification } from 'antd';
import jsonpath from 'jsonpath';
import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Store } from '../../../../../Store';
import {
  linkedChildrenTag,
  linkedFamilyTag,
  linkedLeadsTag,
  linkedParentTag,
} from '../../../Utils/SchedulerSymbols';
import './CreateLead.scss';
import { useSchema } from '../../../Contexts/SchedulerContext/SchedulerContext';
import createLead, { IOnCreateLeadReturn } from '../../../CRUD/createLead';
import { TSchedulerTour } from '../../../Types/SchedulerTypes';
import validateFormData, { IFormFields } from '../../../Utils/validateFormData';
import { useCTContext } from '../../../Contexts/CTContext/CTContext';

interface ICreatLeadProps {
  event: Partial<TSchedulerTour>;
  updateEvent: Updater<Partial<TSchedulerTour>>;
  onCancelCreateLead(): void;
  onLeadCreated(): void;
}

const noForm = { $type: 'Layout.Section', id: 'no-form-yet' };

export default function CreateLead({
  onCancelCreateLead,
  updateEvent,
  onLeadCreated,
  event,
}: ICreatLeadProps) {
  const [invalid, setInvalid] = useState(false);
  const [fields, setFields] = useState<IFormFields>();

  const [isCT] = useCTContext();

  const [creating, setCreating] = useState(false);

  const ep = useCurrentSiteEndpoint();

  const match = useRouteMatch<{
    centreId: string;
    tab?: keyof Store['tabs'];
  }>();

  const centreId = +/^(\d+)/.exec(match.params.centreId)?.[0]!;

  const schema = useSchema('createLead');

  const [shouldUpdate, setShouldUpdate] = useState(0);

  const [formData, updateFormData] = useImmer<Record<string, any>>({});

  useEffect(
    function setFormDataEffect() {
      const contact = event?.[linkedParentTag];

      updateFormData(d => {
        d.contact = contact;
      });

      setShouldUpdate(update + 1);
    },
    [event],
  );

  const [update, forceUpdate] = useRapidUpdater();

  const [data, , onDataChange] = useAdaptiveData(
    formData,
    schema ?? noForm,
    forceUpdate,
    'edit',
    [schema, shouldUpdate],
  );

  const onDataChangeOuter = (
    element: IElement,
    data: any,
    path?: string | undefined,
    key?: string | undefined,
  ) => {
    if (key === 'data' && !!path) {
      updateFormData(d => {
        jsonpath.value(d, path, data);
      });
    }
    onDataChange(element, data, path, key);
  };

  const onCreateLead = () => {
    const form = validateFormData(formData as any);

    if (!form.isValid) {
      setInvalid(true);
      setFields(form.fields);
      return;
    }

    setCreating(true);

    createLead(ep, formData, centreId)
      .then((res: IOnCreateLeadReturn) => {
        updateEvent(d => {
          // Check if already leads and add
          if (!Array.isArray(d[linkedLeadsTag])) {
            d[linkedLeadsTag] = [res.lead];
          } else if (Array.isArray(d[linkedLeadsTag])) {
            d[linkedLeadsTag].push(res.lead);
          }

          // Check if already children and add
          if (!Array.isArray(d[linkedChildrenTag]) && !!res.child) {
            d[linkedChildrenTag] = [res.child];
          } else if (Array.isArray(d[linkedChildrenTag]) && !!res.child) {
            d[linkedChildrenTag].push(res.child);
          }

          d[linkedParentTag] = res.contact;

          d[linkedFamilyTag] = res.family;
        });

        setCreating(false);
        onLeadCreated();
      })
      .catch(err => {
        console.log(err);

        setCreating(false);

        notification.warn({
          message: 'Error creating lead',
          description: `There was an error creating the lead. Please refresh the page and try again. If the problem persists contact your support personnel. \n\nError: ${err}`,
          duration: 0,
        });
      });
  };

  const _onCancelCreateLead = () => {
    onCancelCreateLead();
  };

  const createLeadClass = useComposedClassName(
    function* () {
      yield 'CreateLead';

      if (invalid) {
        yield 'invalid';

        if (!!fields) {
          for (const [fieldName, state] of Object.entries(fields)) {
            if (!state) {
              yield fieldName;
            }
          }
        }
      }
    },
    [fields, invalid],
  );

  return (
    <div className={createLeadClass}>
      <div className="FormContainer">
        {!!creating && (
          <div className="CreatingLead">
            <i className="fal fa-fw fa-spinner-third fa-spin" />
            &ensp;Creating lead...
          </div>
        )}
        <UncontrolledAdaptiveRenderer
          onDataChange={onDataChangeOuter}
          update={update}
          forceUpdate={forceUpdate}
          schema={schema ?? noForm}
          mode={'edit'}
          data={data}
        />
      </div>

      <div className="CreateLeadActions">
        <Button
          onClick={onCreateLead}
          type="primary"
          className="Button Create"
          disabled={creating}
        >
          Create
        </Button>

        <Button
          onClick={_onCancelCreateLead}
          type="text"
          danger
          className="Button Cancel"
          disabled={creating}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}
