export const ValidationErrors = {
  empty: 'Please fill in this field',
};

export type ValidatorRule = {
  isRequired?: boolean;
  validateOnMount?: boolean;
};

export const formValidators = {
  Note: (value: unknown, rules: ValidatorRule) => {
    if (typeof value === 'string') value = value.trim();
    if (rules.isRequired && !value) return ValidationErrors.empty;
    return null;
  },
  Lookup: (value: unknown, rules: ValidatorRule) => {
    if (typeof value === 'string') value = value.trim();
    if (rules.isRequired && typeof value !== 'number') return ValidationErrors.empty;
    return null;
  },
  Choice: (value: unknown, rules: ValidatorRule) => {
    if (typeof value === 'string') value = value.trim();
    if (typeof value === 'boolean') return null;
    if (rules.isRequired && !value) return ValidationErrors.empty;
    return null;
  },
  DateTime: (value: unknown, rules: ValidatorRule) => {
    if (typeof value === 'string') value = value.trim();
    if (rules.isRequired && !value) return ValidationErrors.empty;
    return null;
  },
  Number: (value: unknown, rules: ValidatorRule) => {
    if (typeof value === 'string') value = value.trim();
    if (rules.isRequired && !value) return ValidationErrors.empty;
    return null;
  },
  Email: (value: unknown, rules: ValidatorRule) => {
    if (typeof value === 'string') value = value.trim();
    if (rules.isRequired && !value) return ValidationErrors.empty;
    return null;
  },
  Text: (value: unknown, rules: ValidatorRule) => {
    if (typeof value === 'string') value = value.trim();
    if (rules.isRequired && !value) return ValidationErrors.empty;
    return null;
  },
};

export function pick<T extends object>(origin: T, keys: Array<string>) {
  const value = {} as T;
  if (!origin) return value;
  const originKeys = Object.keys(origin) as Array<keyof T>;

  for (const originKey of originKeys) {
    if (keys.includes(originKey as any)) value[originKey] = origin[originKey];
  }

  return value;
}
