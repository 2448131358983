import { AnyListItem } from '@rapid/data-model';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { IColumn, IColumnProps, TTabs, useStore } from '../../Store';
import DataCell from './DataCell';

interface ITableBodyProps {
  items: any[];
  columns: IColumn[];
  tab: TTabs;
  timezone?: string;
  isLoading: boolean;
}

export default function TableBody(props: ITableBodyProps) {
  const { centreId, tab } = useParams<any>();
  const [store, updateStore] = useStore();
  const history = useHistory();

  const getLink = (item: any) => {
    switch (props.tab) {
      case 'sentOffers':
      case 'enrolled':
        // `/Centres/${centreId}/Bond/${item.id}/${item.lead_id}`;
        return `${tab}/lead-${item.lead_id}`;
      case 'tour':
      case 'preOffers':
        // `/Centres/${centreId}/Tour/${item.id}/${item.tour_lead_id}`;
        return `${tab}/lead-${item.tour_lead_id}`;
      case 'waitlist':
        return `${tab}/lead-${item.lead_id}`;
      case 'tasks':
        return `${tab}/task-${item.id}`;
      default:
        return `/${centreId}`;
    }
  };

  const onItemClickedInner = (item: AnyListItem, i: number) => () => {
    updateStore(draft => {
      draft.tabs[props.tab].selectedIndex = i;
    });

    window.open(getLink(item));
  };

  return (
    <tbody>
      {props.items.map((item, i) => {
        return (
          <tr
            key={item.id}
            onClick={onItemClickedInner(item, i)}
            className={`rapid-body-item ${
              store.tabs[props.tab].selectedIndex === i ? 'selected' : ''
            }`}
          >
            {props.columns.map((column, i) => {
              let Component = column.Content;

              if (!Component) {
                Component = DataCell as React.ComponentType<IColumnProps>;
              }

              return (
                <td key={`${i}-${item.id}`} style={column.css}>
                  {React.createElement(Component, { column, item, timezone: props.timezone })}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
}
