import { Dropdown } from 'antd';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import DateTimePickerDropdown from './DateTimePickerDropdown';
import './DateTimePicker.scss';
import useComposedClassName from '@rapid-platform/use-composed-class-name';
import { IValidTimeRange } from './TimePicker';

export interface IValidDateRange {
  start?: DateTime;
  end?: DateTime;
}

interface IDateTimePickerProps {
  validDateRange?: IValidDateRange;
  validTimeRange?: IValidTimeRange;
  onChange(value?: DateTime): void;
  value?: DateTime;
  label?: string;
}

export default function DateTimePicker({
  validDateRange,
  validTimeRange,
  label,
  onChange,
  value,
}: IDateTimePickerProps) {
  const [visible, setVisible] = useState(false);

  const onVisibleChange = (v: boolean) => {
    setVisible(v);
  };

  const onClose = () => {
    setVisible(false);
  };

  const onDropdownChange = (
    type: 'time' | 'date' | 'clear',
    newValue?: DateTime,
  ) => {
    if (type === 'clear' && !newValue) {
      onChange(undefined);
    }

    if (type === 'date' && !value && !!newValue && newValue.isValid) {
      newValue = DateTime.fromObject({
        year: newValue.year,
        month: newValue.month,
        day: newValue.day,
        hour: newValue.hour,
        minute: newValue.startOf('hour').plus({ minutes: 30 }).minute,
      });
    }

    if (type === 'time' && !!value && !!newValue && newValue.isValid) {
      newValue = DateTime.fromObject({
        year: value.year,
        month: value.month,
        day: value.day,
        hour: newValue.hour,
        minute: newValue.minute,
      });
    }

    if (type === 'date' && !!value && !!newValue && newValue.isValid) {
      newValue = DateTime.fromObject({
        year: newValue.year,
        month: newValue.month,
        day: newValue.day,
        hour: value.hour,
        minute: value.minute,
      });
    }

    if (type !== 'clear' && !!newValue && newValue.isValid) {
      onChange(newValue);
    }
  };

  const prettyDate = useMemo(() => {
    if (!!value && value.isValid) {
      return value.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY);
    }
    return undefined;
  }, [value]);

  const placeholder =
    !!value && !value.isValid ? 'Invalid date or time' : 'Select date & time';

  const inputClass = useComposedClassName(
    function* () {
      yield 'ant-input DateTimeInput';

      if (visible) {
        yield 'ant-input-focused';
      }

      if (!prettyDate) {
        yield 'input-placeholder';
      }
    },
    [visible, prettyDate],
  );

  return (
    <div className="DateTimePicker Label field-wrapper">
      <label className="field-label">{label}</label>
      <Dropdown
        trigger={['click']}
        visible={visible}
        onVisibleChange={onVisibleChange}
        overlayClassName="DateTimePickerOverlay"
        placement="bottom"
        overlay={
          <DateTimePickerDropdown
            value={value}
            onChange={onDropdownChange}
            validDateRange={validDateRange}
            validTimeRange={validTimeRange}
            onClose={onClose}
          />
        }
      >
        <div className={inputClass} title={prettyDate ?? placeholder}>
          <span className="InputValue">
            {prettyDate}
            {!prettyDate && placeholder}
          </span>
          <div className="DTPIcons">
            <i className="fal fa-fw fa-calendar" />
            <i className="fal fa-fw fa-clock" />
          </div>
        </div>
      </Dropdown>
    </div>
  );
}
