import { ManagedViewContext } from '@rapid/sdk';
import React, { useMemo } from 'react';
import { Store, TAgeRanges, ages, useTabContext } from '../Store';
import { odataFilterDays } from '../common/utils/filters';
import CentreTabContent from './CentreTabContent';
import SchedulerTab from './SchedulerTab/SchedulerTab';
import { FetchMyCentresUsersTasksHOC } from './untils';

interface ICentreTabProps {
  centreId: number;
  timezone: string | undefined;
  tab: keyof Store['tabs'];
}

export default function CentreTab({ tab: tabName, centreId, timezone }: ICentreTabProps) {
  const [tab] = useTabContext(tabName);

  const orderby = tab.sort?.ColumnName
    ? `${tab.sort.ColumnName} ${tab.sort.Direction ?? 'asc'}`
    : undefined;

  const filter = useMemo(
    () =>
      [
        ...(tab.odataFilters ?? []),
        tab.selectedAgeRanges &&
          tab.selectedAgeRanges.map(v => `(${ages[v as TAgeRanges]})`).join(' or '),
        tab.days && odataFilterDays(tab.days),
      ]
        .filter(i => !!i)
        .join(' and '),
    [tab.selectedAgeRanges, tab.odataFilters, tab.days],
  );

  if (tabName === 'tourSchedule') {
    return <SchedulerTab centreId={centreId} timezone={timezone} />;
  }

  if (!tab.viewContext?.list) {
    return <></>;
  }

  if (tabName === 'tasks') {
    return (
      <FetchMyCentresUsersTasksHOC
        filter={filter}
        tab={tab}
        searchTerm={tab?.searchKey}
        orderby={orderby}
      >
        <CentreTabContent selectedTab={tabName} centreId={centreId} />
      </FetchMyCentresUsersTasksHOC>
    );
  }

  return (
    <ManagedViewContext
      {...tab.viewContext!}
      linkedTo={`Centres/${centreId}`}
      searchTerm={tab.searchKey}
      filter={filter}
      orderby={orderby}
    >
      <CentreTabContent selectedTab={tabName} centreId={centreId} timezone={timezone} />
    </ManagedViewContext>
  );
}
