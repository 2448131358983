import { IColumnProps } from '../../Store';
import React, { useMemo } from 'react';
import { DateTime } from 'luxon';

export default function DateCell({ column, item }: IColumnProps) {
  const dateTimeValue = useMemo(
    () => DateTime.fromISO(item[column.path]),
    [item[column.path]]
  );

  return <span>{dateTimeValue.toLocaleString(DateTime.DATE_MED)}</span>;
}
