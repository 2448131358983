import { notification } from 'antd';

export type TLightboxErrorAction = 'close' | 'notify' | 'close-notify';

export type TCatchLightboxError = (
  action: TLightboxErrorAction,
) => (err: any) => void;

export default function useLightboxError(
  closeLightbox: () => void,
): TCatchLightboxError {
  function catchLigtboxError(action: TLightboxErrorAction) {
    return function _catchLigtboxError(err: any) {
      console.log(err, action);

      if (action !== 'close') {
        notification.open({
          message: 'We had some trouble getting that',
          description: err.message,
        });
      }

      if (action !== 'notify') {
        closeLightbox();
      }
    };
  }

  return catchLigtboxError;
}
