import React, { ComponentProps } from 'react';
import './note-field.scss';
import { useComposedClassName } from '@rapid/sdk';

interface INoteFieldProps extends ComponentProps<'textarea'> {
  label: string;
  error?: string;
}

function NoteField({ className, label, error, id, ...inputProps }: INoteFieldProps) {
  const cn = useComposedClassName(
    function* () {
      yield 'field-container';
      if (className) yield className;
    },
    [className],
  );

  return (
    <div className={cn} data-required={!!inputProps.required} data-invalid={!!error}>
      <label htmlFor={id} className="field-container__label">
        {label}
      </label>

      <textarea
        className="note-field field-container__field"
        id={id}
        aria-errormessage={`${id}-error`}
        rows={4}
        {...inputProps}
      />

      <p className="field-container__error" id={`${id}-error`}>
        {error}
      </p>
    </div>
  );
}

export default NoteField;
